import { Component } from '@angular/core';
import { InfoMessageComponent } from '../dialogs/info-message/info-message.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { StoreService } from 'src/app/services/store.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { AuthService } from '../auth.service';
import { Icons } from 'src/app/constants/icons';
import { MobileOtpComponent } from '../dialogs/mobile-otp/mobile-otp.component';
import { MessagingService } from 'src/app/services/messaging.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { FirebaseMessagingService } from 'src/app/services/firebase-messaging.service';
import { repayment } from 'src/app/constants/meta-data';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-multi-login',
  templateUrl: './multi-login.component.html',
  styleUrls: ['./multi-login.component.scss'],
})
export class MultiLoginComponent {
  icon: any;
  multiLoginForm!: FormGroup;
  isDisable: boolean = false;
  currentUrl: string = '';
  loginType: string = 'Login Type';

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private store: StoreService,
    public toastr: ToastrService,
    private router: Router,
    public loaderService: LoaderService,
    public messagingService: MessagingService,
    private authService: AuthService,
    private analytics: AnalyticsService,
    private firebaseService: FirebaseMessagingService,
    private titleService: Title,
    private metaService: Meta
  ) {
    this.icon = Icons;
  }

  async ngOnInit() {
    const metaTags = repayment;
    this.titleService.setTitle(metaTags.title);
    metaTags.meta.forEach((tag: any) => this.metaService.addTag(tag));

    let getToken = this.store.get('accessToken');
    let customerId = this.store.get('customerId') || undefined;
    let loanId = this.store.get('loanId') || undefined;
    this.analytics.sendDataToGoogleAnalytics('web_login_page_load', {
      authType: 'login',
      customerId,
      loanId,
    });
    this.currentUrl = this.router.url;
    this.store.set({ key: 'goal', value: 'repayment' });
    if (getToken != null && this.currentUrl == '/auth/app') {
      this.store.clearAuthStore();
    }

    this.multiLoginForm = this.fb.group({
      type: ['', Validators.required],
      input: [''],
    });

    let refreshToken = this.store.get('refreshToken');
    if (refreshToken != null && this.currentUrl != '/auth/app') {
      this.setNewAccessToken();
    }
  }
  onTypeChange(event: any) {
    this.multiLoginForm.get('input')?.setValue('');
    const selectedType = event.target.value;
    this.setValidation(selectedType);

    const selectElement = event.target as HTMLSelectElement;
    const selectedText =
      selectElement.options[selectElement.selectedIndex].text;
    this.loginType = selectedText;
  }
  setValidation(type: string) {
    const inputControl = this.multiLoginForm.get('input');

    // Clear existing validators
    inputControl?.clearValidators();

    // Set validators based on the selected type
    switch (type) {
      case '1': // Phone Number
        inputControl?.setValidators([
          Validators.required,
          Validators.pattern(/^[789]\d{9}$/),
        ]);
        break;

      case '2': // Email
        inputControl?.setValidators([
          Validators.required,
          Validators.pattern(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
          ),
        ]);
        break;

      case '3': // Loan ID
        inputControl?.setValidators([
          Validators.required,
          Validators.pattern(/^\d+$/), // Assuming Loan ID is numeric
        ]);
        break;

      default:
        inputControl?.setValidators(null);
        break;
    }

    // Update form control state
    inputControl?.updateValueAndValidity();
  }

  ngAfterViewInit() {
    let width: string = window.innerWidth <= 768 ? '80%' : '50%';
    if (this.currentUrl == '/auth/app') {
      this.store.set({ key: 'platform', value: 'app' });
      this.store.set({ key: 'comeFromApp', value: 'true' });
    } else {
    }
  }

  setNewAccessToken() {
    this.loaderService.show();
    return new Promise((resolve) => {
      this.authService
        .getAccessToken({
          refreshToken: '' + this.store.get('refreshToken'),
        })
        .subscribe({
          next: async (res: any) => {
            if (res.success && res.data && res.data.accessToken) {
              this.store.set({
                key: 'accessToken',
                value: res.data.accessToken,
              });
              try {
                let fcmToken: any = await this.firebaseService.getToken();
                if (fcmToken) {
                  this.authService.saveFCMToken({ token: fcmToken }).subscribe({
                    next: (res: any) => {},
                  });
                }
              } catch (error) {}
              this.loaderService.hide();
              this.router.navigate(['loan/apply']);
              return resolve(true);
            } else {
              this.store.clearAuthStore();
              this.router.navigate(['auth/mobile']);
            }
            this.loaderService.hide();
            return resolve(false);
          },
          error: (err: any) => {
            this.router.navigate(['auth/mobile']);
            this.store.clearAuthStore();
          },
        });
    });
  }

  keyUpEvent(event: any) {
    const inputValue = event.target.value;
    const type = this.multiLoginForm.get('type')?.value;
    let validValue = '';
    switch (type) {
      case '1': // Phone Number
        const phonePattern = /^[789]\d{9}$/;
        if (phonePattern.test(inputValue)) {
          validValue = inputValue;
        } else {
          validValue = inputValue.replace(/[^0-9]/g, '');
        }
        break;

      case '2': // Email
        // Email pattern
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailPattern.test(inputValue)) {
          validValue = inputValue;
        } else {
          validValue = inputValue; // You may want to handle invalid emails differently
        }
        break;

      case '3': // Primary Number (like loan ID)
        // Assuming a primary number can be any numeric value
        validValue = inputValue.replace(/[^0-9]/g, '');
        break;

      default:
        // If no valid type is selected, fallback to no validation or default handling
        validValue = inputValue;
        break;
    }
    this.multiLoginForm.patchValue({
      mobile: validValue,
    });
  }

  onSubmit() {
    this.isDisable = true;
    this.loaderService.show();
    let { input, type } = this.multiLoginForm.value;
    this.authService
      .authRepayment({
        input: input,
        type: type,
      })
      .subscribe({
        next: (res: any) => {
          this.isDisable = false;
          if (res.success) {
            let width: string = window.innerWidth <= 768 ? '100%' : '40%';
            this.dialog.open(MobileOtpComponent, {
              data: { input, type },
              width: width,
              disableClose: true,
            });
          } else {
            this.toastr.error(res.message, 'Error!');
          }
          this.loaderService.hide();
        },
      });
  }
}
