import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MarketingEventWhistleService {
  private resultSubject = new BehaviorSubject<any>({ success: true });
  public result$ = this.resultSubject.asObservable();

  constructor(private http: HttpClient) {}

  // Public method for triggering the API call
  pixelMarketingEventAPI(linkId: any, revenue: any, goalName: string) {
    const url = `${environment.pixelMarketingEventWhistleURL}?linkid=${linkId}&revenue=${revenue}&goal_name=${goalName}`;
    console.log('Request URL:', url);
    this.http.get<any>(url).subscribe(result => {
    },e=>{
    });
  }
  thoughverSetMarketingEventAPI(tid: any) {
    const url = `${environment.thoughverseMarketingURL}?m=12672&tid=${tid}`;
    console.log('Request URL:', url);
    this.http.get<any>(url).subscribe(result => {
    },e=>{
    });
  }
}
