<div class="dialog-card-view">
  <div class="card-header content-center f-dir-column">
    <div class="icon-box">
      <img [src]="icon.locationLogo" />
    </div>
    <p class="header-title">Access to User Location</p>
  </div>

  <div class="dialog-content mt-0">
    <p class="text-content text-center">
      We access your location to verify and check our availability and
      servicibility in your area
    </p>
  </div>
  <div class="dialog-btn-wrapper">
    <button (click)="continue()" class="card-view-button fit-btn">Okay</button>
  </div>
</div>
