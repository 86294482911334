import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loader$ = new BehaviorSubject<Boolean>(false);
  public loader = this.loader$.asObservable();

  constructor() {}

  show() {
    return this.loader$.next(true);
  }

  hide() {
    return this.loader$.next(false);
  }
}
