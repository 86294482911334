import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Icons } from 'src/app/constants/icons';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { PwaInstallService } from 'src/app/services/pwa-install.service';

@Component({
  selector: 'app-install-pwa-dialog',
  templateUrl: './install-pwa-dialog.component.html',
  styleUrls: ['./install-pwa-dialog.component.scss'],
})
export class InstallPwaDialogComponent {
  icon: any;

  constructor(
    public dialog: MatDialog,
    public pwaService: PwaInstallService,
    private analytics: AnalyticsService,

    private dialogRef: MatDialogRef<InstallPwaDialogComponent>
  ) {
    this.icon = Icons;
  }

  continue() {
    this.analytics.sendDataToGoogleAnalytics('PWA_Downloaded', {});
    this.dialog.closeAll();
    this.pwaService.promptInstall();
  }

  skipInstall() {
    this.dialogRef.close();
  }
}
