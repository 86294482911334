<div class="dialog-card-view">
  <div class="card-header content-center f-dir-column">
    <div class="icon-box">
      <img [src]="data.hideSubmitBtn ? icon.locationLogo : icon.informationIcon" alt="Icon" />
    </div>
  </div>
  <div class="dialog-content">
    <p class="text-content text-center">{{ data.message }}</p>
  </div>
  <div class="dialog-btn-wrapper">
    <button type="button" class="card-view-button" (click)="handleClick()" *ngIf="!data.hideSubmitBtn">
      Focus Window
    </button>
  </div>
</div>
