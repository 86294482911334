import { Component } from '@angular/core';
import { LoaderService } from './services/loader.service';
import { Icons } from './constants/icons';
import { LocationService } from './services/location.service';
import { environment } from 'src/environments/environment';
import FlyySDK from 'flyy-web-sdk';
import { ScriptInjectorService } from './services/script-loader.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'creditt-web-app';
  permission: boolean = true;
  icon: any;
  locationPermission: boolean = false;
  constructor(
    public loaderService: LoaderService,
    public locationService: LocationService,
    // private scriptInjector: ScriptInjectorService

  ) {
    let flysdd = new FlyySDK();
    flysdd.startReferralTracking();
    this.icon = Icons;
    if (environment.production == true) {
      window.console.log = function () { };
      window.console.error = function () { };
      window.console.warn = function () { };
      window.console.time = function () { };
      window.console.timeEnd = function () { };
    }
  }

  ngOnInit() {
    // this.scriptInjector.injectScript();
    if (window.location.pathname != '/auth/app') this.locationService.checkPermission();
    if (('serviceWorker' in navigator) && !environment.serviceWorkerStatus) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (const registration of registrations) {
          registration.unregister().then((boolean) => {
            if (boolean) {
              console.log('Service worker unregistered successfully.');
            } else {
              console.log('Service worker unregistration failed.');
            }
          });
        }
      });
    }

  }
}
