<div class="homeHeader">
  <app-header
    [title]="common.customerNickName"
    [isShowBackButton]="false"
  ></app-header>
</div>
<div class="home-main-card">
  <div class="card-view sub-card">
    <div class="card-content-sec">
      <div class="btn-sec">
        <button (click)="openAppBtn()" class="card-view-button">
          Open App
        </button>
      </div>
    </div>
  </div>
</div>
