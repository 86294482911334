import { Injectable } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { environment } from 'src/environments/environment';

const theme = environment.theme == 'dark' ? 'dark' : 'light';

@Injectable({
  providedIn: 'root',
})
export class Lottie {
  video: AnimationOptions = {
    path: `../../assets/lottie/theme/${theme}/video_verify.json`,
  };
  creditScore: AnimationOptions = {
    path: `../../assets/lottie/theme/${theme}/credit_risk_core.json`,
  };
  applyLoan: AnimationOptions = {
    path: `../../assets/lottie/theme/${theme}/apply_now.json`,
  };
  arrowRight: AnimationOptions = {
    path: `../../assets/lottie/theme/${theme}/arrow_right.json`,
  };
  speedoMeter: AnimationOptions = {
    path: `../../assets/lottie/theme/${theme}/speed_meter.json`,
  };
  infoMsg: AnimationOptions = {
    path: `../../assets/lottie/theme/${theme}/info-msg.json`,
  };
  reTake: AnimationOptions = {
    path: `../../assets/lottie/theme/${theme}/re_take.json`,
  };
  noData: AnimationOptions = {
    path: `../../assets/lottie/theme/${theme}/no_data.json`,
  };
  pending: AnimationOptions = {
    path: `../../assets/lottie/theme/${theme}/user-verification-pending.json`,
  };
  success: AnimationOptions = {
    path: `../../assets/lottie/theme/${theme}/successfully.json`,
  };
  congratulation: AnimationOptions = {
    path: `../../assets/lottie/theme/${theme}/congratulations.json`,
  };
  userDecline: AnimationOptions = {
    path: `../../assets/lottie/theme/${theme}/user_decline_new.json`,
  };
  login: AnimationOptions = {
    path: `../../assets/lottie/theme/${theme}/login.json`,
  };
}
