<div class="header-main">
  <div class="left-wrapper">
    <!-- <button class="card-view-button logoutButton" (click)="common.openAndroidApp()"><img [src]="icon.mobile"></button> -->
    <button
      *ngIf="isShowBackButton"
      class="card-view-button action-btn"
      (click)="goToBack()"
    >
      <img [src]="icon.backIcon" />
    </button>
    <div>
      <p class="greeting" *ngIf="isShowGreeting && !mainTitle">
        {{ greeting }}
      </p>
      <p class="customer-name" *ngIf="isShowTitle && !mainTitle">{{ title }}</p>
      <p class="rout-title" *ngIf="mainTitle">{{ mainTitle }}</p>
    </div>
  </div>
  <div *ngIf="isShowLogoutButton">
    <!-- <button class="card-view-button logoutButton" (click)="common.openAndroidApp()"><img [src]="icon.mobile"></button> -->
    <button class="card-view-button action-btn" (click)="logout()">
      <img [src]="icon.logout" />
    </button>
  </div>
</div>
