import { Component, Inject, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../auth.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { CryptoEncryptionService } from 'src/app/services/crypto-encryption.service';
import { StoreService } from 'src/app/services/store.service';
import { MessagingService } from 'src/app/services/messaging.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Icons } from 'src/app/constants/icons';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { FirebaseMessagingService } from 'src/app/services/firebase-messaging.service';
import { MarketingEventWhistleService } from 'src/app/services/marketing-event-whistle.service';

@Component({
  selector: 'app-aadhar-otp',
  templateUrl: './aadhar-otp.component.html',
  styleUrls: ['./aadhar-otp.component.scss'],
})
export class AadharOtpComponent {
  otpForm!: FormGroup;
  aadharNumber: string = '';
  aadharAuthType: 'KYC' | 'REKYC' = 'KYC';
  icon: any;

  constructor(
    private authService: AuthService,
    public toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    public loaderService: LoaderService,
    private encrypt: CryptoEncryptionService,
    private store: StoreService,
    private messagingService: MessagingService,
    private dialog: MatDialog,
    private analytics: AnalyticsService,
    private firebaseService: FirebaseMessagingService,
    private marketingevent: MarketingEventWhistleService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    console.log('::dialogData', dialogData);

    if (dialogData.customerId && dialogData.customerId == '') {
      this.router.navigate(['auth/aadhar'], {
        queryParams: {
          [this.encrypt.encryptionAES('type')]:
            this.encrypt.encryptionAES('KYC'),
        },
      });
    }
    this.aadharNumber = dialogData.customerId;
    this.aadharAuthType = dialogData.aadharAuthType;
    this.icon = Icons;
    this.otpForm = this.toFormGroup(this.formInput);
    setTimeout(() => {
      this.rows._results[0].nativeElement.focus();
    }, 100);
  }

  formInput = ['otp1', 'otp2', 'otp3', 'otp4', 'otp5', 'otp6'];
  @ViewChildren('formRow') rows: any;

  toFormGroup(elements: any) {
    const group: any = {};
    elements.forEach((key: any) => {
      group[key] = new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(1),
        Validators.maxLength(1),
      ]);
    });
    return new FormGroup(group);
  }

  keyUpEvent(event: any, index: any) {
    const validCharacters = /^[0-9]*$/;
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1;
    } else {
      pos = index + 1;
    }
    if (pos > -1 && pos < this.formInput.length) {
      if (
        (this.rows._results[index].nativeElement.value != '' &&
          validCharacters.test(
            this.rows._results[index].nativeElement.value
          )) ||
        (event.keyCode === 8 && event.which === 8)
      ) {
        this.rows._results[pos].nativeElement.focus();
      } else {
        this.rows._results[index].nativeElement.value = '';
      }
    }
  }

  ngOnInit() {
    this.startTimer();
  }

  timeLeft: number = 0;
  interval: any;
  startTimer() {
    this.timeLeft = 60;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  resendOtp() {
    this.startTimer();
    this.loaderService.show();
    this.authService
      .registerAadharSendOtp({
        aadharNumber: this.aadharNumber,
        type: this.aadharAuthType,
      })
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            this.toastr.success('Resend Otp successfully', 'Success!');
            this.otpForm.patchValue({
              otp1: '',
              otp2: '',
              otp3: '',
              otp4: '',
              otp5: '',
              otp6: '',
            });
            setTimeout(() => {
              this.rows._results[0].nativeElement.focus();
            }, 100);
          } else {
            this.toastr.error(res.message, 'Error!');
          }
          this.loaderService.hide();
        },
        error: (err: any) => {
          this.loaderService.hide();
        },
      });
  }

  async onSubmit() {
    if (
      this.otpForm.value.otp1 != '' &&
      this.otpForm.value.otp2 != '' &&
      this.otpForm.value.otp3 != '' &&
      this.otpForm.value.otp4 != '' &&
      this.otpForm.value.otp5 != '' &&
      this.otpForm.value.otp6 != ''
    ) {
      this.loaderService.show();
      let otp =
        '' +
        this.otpForm.value.otp1 +
        this.otpForm.value.otp2 +
        this.otpForm.value.otp3 +
        this.otpForm.value.otp4 +
        this.otpForm.value.otp5 +
        this.otpForm.value.otp6;
      let fcmToken: any = '';
      try {
        fcmToken =
          this.store.get('platform') != 'app'
            ? await this.messagingService.requestPermission()
            : '';
      } catch (error) {}

      if (
        (fcmToken && fcmToken != '') ||
        this.store.get('platform') == 'app' ||
        true
      ) {
        this.authService
          .registerAadharVerifyOtp({
            aadharNumber: this.aadharNumber,
            otp: otp,
            type: this.aadharAuthType,
          })
          .subscribe({
            next: async (res: any) => {
              if (res.success && res.data) {
                
                //@valueleaf
                if (this.store.get('puretech_ma') == 'valueleaf' && this.store.get('click_id')) {
                  this.marketingevent.pixelMarketingEventAPI(this.store.get('click_id'), '', 'Aadhar_OTP');
                };

                if (res.data.stage == 'PROFILE') {
                  if (this.store.get('loanCount') == '0')
                    this.analytics.sendDataToGoogleAnalytics(
                      'Web_First_Time_Aadhar_VERIFY',
                      { }
                    );
                  this.router.navigate(['auth/register']);
                } else {
                  this.store.set({
                    key: 'accessToken',
                    value: res.data.accessToken,
                  });
                  this.store.set({
                    key: 'refreshToken',
                    value: res.data.refreshToken,
                  });
                  this.store.set({ key: 'mobileAccessToken', value: '' });
                  try {
                    const fcmToken1: any =
                      await this.firebaseService.getToken();
                      if (fcmToken1) {
                        this.authService
                          .saveFCMToken({ token: fcmToken1 })
                          .subscribe({
                            next: (res: any) => {},
                          });
                      }
                  } catch (error) {}
                  if (res.data.stage == 'DASHBOARD') {
                    this.store.remove('passcode');
                    this.router.navigate(['home']);
                  } else if (res.data.stage == 'SETPASSCODE') {
                    this.router.navigate(['more/pass-code']);
                    this.store.set({ key: 'passcode', value: 'true' });
                  }
                }
                this.dialog.closeAll();
              } else {
                this.toastr.error(res.message, 'Error!');
              }
              this.loaderService.hide();
            },
            error: (err: any) => {
              this.loaderService.hide();
            },
          });
      } else {
        this.toastr.error(
          'Token not generated. please reload browser.',
          'Firebase Error!'
        );
      }
    }
  }
}
