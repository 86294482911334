<app-brand-logo></app-brand-logo>
<div class="card-view aadhar-main">
  <div class="aadhar-main-header">
    <div class="header-title">
      <p class="title">Let's Get Started</p>
      <p class="sub-title">Enter Your Aadhaar Number</p>
    </div>
    <div class="aadhar-main-icon">
      <img [src]="icon.aadhar" />
    </div>
  </div>
  <div class="aadhar-main-contain">
    <form [formGroup]="aadharForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <div class="aadhar-input-group">
          <input type="text" inputmode="numeric" *ngFor="let input of formInput; index as i" #formRow
            [formControlName]="input" class="form-control" (keyup)="keyUpEvent($event, i)" minlength="4" maxlength="4"
            placeholder="XXXX" />
        </div>
      </div>
      <div class="button-div">
        <button type="submit" [disabled]="!aadharForm.valid" class="card-view-button">
          Continue
        </button>
      </div>
      <div class="differentMobile">
        <p (click)="differentMobile()">Login with different mobile number</p>
      </div>
    </form>
  </div>
</div>