<div class="dialog-card-view">
  <div class="card-header content-center f-dir-column">
    <div class="icon-box">
      <img [src]="icon.logoutIcon" />
    </div>
    <h4 class="header-title mb-0">Logout!</h4>
  </div>
  <div cl mb-0ass="dialog-content content-center mt-0">
    <p class="text-content text-center mt-5 mb-15">
      Are you sure you want to logout?
    </p>
    <div class="dialog-btn-wrapper">
      <button (click)="yes()" class="card-view-button half-btn">Yes</button>
      <button (click)="no()" class="card-view-button secondary-btn half-btn">
        No
      </button>
    </div>
  </div>
</div>
