import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Route, Router } from '@angular/router';
import { Icons } from 'src/app/constants/icons';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent {
  icon: any;
  @Input() selectedMenuIndex = 0;

  menuDetails = [
    {
      name: 'Home',
      activeIcon: 'assets/svg/theme/light/home_ic.svg',
      inactiveIcon: 'assets/svg/theme/light/home_normal_ic.svg',
      isSelected: false,
      route: 'loan/apply',
    },
    // {
    //   name: 'Support',
    //   activeIcon: 'assets/svg/theme/light/support_ic.svg',
    //   inactiveIcon: 'assets/svg/theme/light/support_normal_ic.svg',
    //   isSelected: false,
    //   route: 'loan/apply',
    // },
    {
      name: 'History',
      activeIcon: 'assets/svg/theme/light/loan_history_ic.svg',
      inactiveIcon: 'assets/svg/theme/light/loan_history_normal_ic.svg',
      isSelected: false,
      route: 'history/loans',
    },
    {
      name: 'More Info',
      activeIcon: 'assets/svg/theme/light/more_info_ic.svg',
      inactiveIcon: 'assets/svg/theme/light/more_normal_ic.svg',
      isSelected: false,
      route: 'more/info',
    },
  ];

  constructor(private router: Router, private cdr: ChangeDetectorRef) {
    this.icon = Icons;
  }

  ngAfterViewInit() {
    this.selectMenu(this.menuDetails[this.selectedMenuIndex]);
    this.cdr.detectChanges();
  }

  selectMenu(menu: any) {
    this.menuDetails.forEach((element: any) => {
      element.isSelected = false;
      if (element?.name == menu?.name) {
        element.isSelected = true;
        this.router.navigate([menu?.route]);
      }
    });
  }
}
