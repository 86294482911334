export const MoreInfo = [
  {
    name: 'Profile',
    icon: 'assets/svg/theme/light/profile_ic.svg',
    route: '/more/profile-detail',
  },
  {
    name: 'Terms & Conditions',
    icon: 'assets/svg/theme/light/terms-conditions_ic.svg',
    link: 'https://credittnow.com/policy?policies=terms-and-conditions&isMobile=true',
  },
  {
    name: 'Privacy Policy',
    icon: 'assets/svg/theme/light/privacy-policy_ic.svg',
    link: 'https://credittnow.com/policy?policies=privacy-policy&isMobile=true',
  },

  {
    name: 'FAQ’s',
    icon: 'assets/svg/theme/light/faq_ic.svg',
    link: 'https://credittnow.com/faq',
  },
  {
    name: 'Contact Us ',
    icon: 'assets/svg/theme/light/contact-us_ic.svg',
    route: '/more/contact-us',
  },
  {
    name: 'Passcode Change',
    icon: 'assets/svg/theme/light/passcode_ic.svg',
  },
  {
    name: 'Clear Sensitive Data',
    icon: 'assets/svg/theme/light/clear-sensitive-data_ic.svg',
  },
  {
    name: 'View partnered NBFC',
    icon: 'assets/svg/theme/light/view-partnered_ic.svg',
  },
  {
    name: 'Sign Out',
    icon: 'assets/svg/theme/light/logout_ic.svg',
  },
];

export const MoreInfoRoutes = ['/more/profile-detail', '/more/contact-us','/more/pass-code'];
