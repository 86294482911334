// flyy-sdk.service.ts
import { Injectable } from '@angular/core';
import FlyySDK from 'flyy-web-sdk';
import { StoreService } from './store.service'; 
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FlyyWebSDKService {
  private flyySDK: any;
  private isInitialized: boolean = false;

  constructor(private store: StoreService) {
    this.flyySDK = new FlyySDK();
  }
  private initObject: any;
  // Initialize FlyySDK
  initializeFlyySDK(token: string, nickName: string): void {
    if (this.isInitialized) {
      return;
    }

    this.initObject = {
      package_name: environment.flyyConfig.packageName,
      partner_id: environment.flyyConfig.partnerId,
      ext_user_token: token,
      attachMode: environment.flyyConfig.attachMode,
      environment: environment.flyyConfig.environment,
      device_id: this.store.get('flyyDeviceId')
    };

    this.flyySDK.setActionButtonPosition('left');
    this.flyySDK.setActionButtonColor('#faa232');
    this.flyySDK.setActionButtonText('Reward Points');
    this.flyySDK.setUserName(nickName || '');
    this.flyySDK.init(JSON.stringify(this.initObject));

    this.isInitialized = true;
  }

  OpenFlyySDK(): void {
    if (this.isInitialized) {
      this.flyySDK.clickToOpenSDK(this.initObject);
    } else {
      console.error('FlyySDK is not initialized yet.');
    }
  }
  resetSDK(): void {
    this.flyySDK = new FlyySDK();
    this.isInitialized = false;
  }
}
