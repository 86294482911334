import { Injectable } from '@angular/core';
import { FirebaseApp, getApp, initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setUserProperties, Analytics } from 'firebase/analytics';
import { environment } from 'src/environments/environment';
import { FirebaseError } from 'firebase/app';

declare var fbq : any;
@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private eventApp: FirebaseApp;
  private analytics: Analytics;

  constructor() {
    try {
      this.eventApp = getApp('eventsApp');
    } catch (error) {
      if (error instanceof FirebaseError && error.code === 'app/no-app') {
        // If the app doesn't exist, initialize it
        this.eventApp = initializeApp(environment.firebaseEventConfig, 'eventsApp');
      } else {
        throw error; // re-throw other errors
      }
    }

    // Initialize the Firebase Analytics instance for the event app
    this.analytics = getAnalytics(this.eventApp);
  }

  async initializeUser(data: any): Promise<boolean> {
    try {
      await setUserProperties(this.analytics, data);
      return true;
    } catch (error) {
      console.error('Error setting user properties:', error);
      return false;
    }
  }
  removeUndefinedOrNull(obj:any) {
    Object.keys(obj).forEach(key => {
      if (obj[key] === undefined || obj[key] === null) {
        delete obj[key];
      }
    });
    return obj;
  }
  
  sendDataToGoogleAnalytics(event: string, data: Object): boolean {
    try {
      fbq('track', event, this.removeUndefinedOrNull(data));
    } catch (error) {
      console.log(error,"Fb Event issue");
    }
    try {
      logEvent(this.analytics, event, data);
      return true;
    } catch (err) {
      console.error('Error sending data to Google Analytics:', err);
      return false;
    }
  }
}
