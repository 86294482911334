import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  currentMessage = new BehaviorSubject(null);

  constructor(private afMessaging: AngularFireMessaging) {}

  requestPermission() {
    return new Promise((resolve)=>{
      this.afMessaging.requestToken.subscribe(
        (token) => {
          console.log('Permission granted! Save to server: ', token);
          return resolve(token);
        },
        (error) => {
          console.error('Permission denied: ', error);
          return resolve(false);
        }
      );
    });
  }
  // receiveMessage() {
  //   this.afMessaging.messages.subscribe((payload:any) => {
  //     console.log('Received message:', payload);
  //     this.currentMessage.next(payload);
  //   });
  // }
}