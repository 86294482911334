import { Injectable } from '@angular/core';
import { CredittScoreService } from '../components/creditt-score/creditt-score.service';
import { ToastrService } from 'ngx-toastr';
import { StoreService } from './store.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  customerNickName: string = '';
  public headerDetail$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(
    private credittScoreService: CredittScoreService,
    private toastr: ToastrService,
    private store: StoreService
  ) {}

  //creditt score
  private getScoreData(score: number, type: string) {
    let scoreTitle = 'Poor';
    let scoreColor = '#f13c3e';
    if (score > 850) {
      scoreTitle = 'Excellent';
      scoreColor = '#08af56';
    } else if (score > 805 && score <= 850) {
      scoreTitle = 'Good';
      scoreColor = '#fdb639';
    } else if (score >= 761 && score <= 805) {
      scoreTitle = 'Below Average';
      scoreColor = '#f38033';
    } else {
      scoreTitle = 'Poor';
      scoreColor = '#f13c3e';
    }
    return type == 'score'
      ? score
      : type == 'title'
      ? scoreTitle
      : type == 'color'
      ? scoreColor
      : { scoreColor: scoreColor, scoreTitle: scoreTitle, score: score };
  }
  getCredittScore(type = 'all') {
    return new Promise((resolve) => {
      let getScore = this.store.get('credittScore');
      if (getScore != null) {
        return resolve(this.getScoreData(Number(getScore), type));
      } else {
        this.credittScoreService.creditScore().subscribe({
          next: (res: any) => {
            if (res.success) {
              this.store.set({ key: 'credittScore', value: '' + res.data });
              return resolve(this.getScoreData(Number(res.data), type));
            } else {
              this.toastr.error(res.message, 'Error!');
              return resolve(false);
            }
          },
        });
      }
    });
  }

  openAndroidApp() {
    const userData: any = {
      type: this.store.get('authType'),
      status: true,
      token: this.store.get('apiAccessToken'),
      customerId: this.store.get('customerId'),
    };
    const appUrlScheme = environment.appDeeplink
      ? environment.appDeeplink
      : 'myapp://credittnow';
    let appUrl = appUrlScheme;
    const queryParamString = Object.keys(userData)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(userData[key])}`
      )
      .join('&');

    if (queryParamString) {
      appUrl += `?${queryParamString}`;
    }
    window.location.href = appUrl;
  }
}
