import { Component, ChangeDetectorRef, HostListener } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Icons } from 'src/app/constants/icons';
import { LoaderService } from 'src/app/services/loader.service';
import { AuthService } from '../auth.service';
import { StoreService } from 'src/app/services/store.service';
import { MoreInfoRoutes } from '../../more-info/more-info-interface';
import { CommonService } from 'src/app/services/common.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-passcode',
  templateUrl: './passcode.component.html',
  styleUrls: ['./passcode.component.scss'],
})
export class PasscodeComponent {
  icon: any;
  firstPasscode: string = '';
  confirmPasscode: string = '';
  isNavigationBarShow = true;
  moreInfoRoutes = MoreInfoRoutes;
  private subscriptions: Subscription[] = [];
  mainTitle: string = '';

  constructor(
    public loaderService: LoaderService,
    public authService: AuthService,
    public toaster: ToastrService,
    public store: StoreService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public common: CommonService
  ) {
    this.icon = Icons;
  }
  ngOnInit() {
    this.common.headerDetail$.next({ mainTitle: 'Passcode Change' });
    this.subscriptions.push(
      this.common.headerDetail$.subscribe((headerDetail) => {
        if (headerDetail && headerDetail.mainTitle) {
          this.mainTitle = headerDetail.mainTitle;
        }
      })
    );
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    this.onKeyPress(event);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  numberKeypad: boolean[] = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];
  passcode: string[] = ['', '', '', ''];

  onKeyPress(event: KeyboardEvent) {
    // Get the key code of the pressed key
    const keyCode = event.keyCode;

    // Check if the key is a number (0-9) or a control key (e.g., Backspace, Delete)
    if (
      (keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      [8, 46].includes(keyCode)
    ) {
      let numberIndex: any = 11;
      if (keyCode === 8 && keyCode === 8) {
        this.onClick('');
      } else {
        numberIndex = Number(event.key) == 0 ? 10 : event.key;
        this.onClick(event.key);
      }
      this.numberKeypad[Number(numberIndex) - 1] = true;
      setTimeout(() => {
        this.numberKeypad[Number(numberIndex) - 1] = false;
        this.cdr.detectChanges();
      }, 300);
      this.cdr.detectChanges();
    } else {
      event.preventDefault();
    }
  }

  onClick(number: string) {
    if (number !== '') {
      const emptyIndex = this.passcode.indexOf('');
      if (emptyIndex !== -1) {
        this.passcode[emptyIndex] = number;
      }
    } else {
      const lastIndex = this.passcode
        .slice()
        .reverse()
        .findIndex((value) => value !== '');
      const lastNonBlankIndex =
        lastIndex >= 0 ? this.passcode.length - 1 - lastIndex : -1;
      this.passcode[lastNonBlankIndex] = '';
    }

    if (this.passcode.indexOf('') === -1) {
      if (this.firstPasscode != '') {
        this.confirmPasscode = this.passcode.join('');
        this.checkPasscode();
      } else {
        this.firstPasscode = this.passcode.join('');
        this.passcode.fill('');
        this.loaderService.show();
        setTimeout(() => {
          this.loaderService.hide();
        }, 1000);
      }
    }
  }

  checkPasscode() {
    console.log('::this.firstPasscode::', this.firstPasscode);
    console.log('::this.confirmPasscode::', this.confirmPasscode);
    if (this.firstPasscode == this.confirmPasscode) {
      this.loaderService.show();
      this.authService
        .setPasscode({
          passcode: this.confirmPasscode,
        })
        .subscribe({
          next: (res: any) => {
            if (res.success) {
              this.store.remove('passcode');
              this.store.set({ key: 'customerId', value: res.data.customerId });
              if (Boolean(this.store.get('comeFromApp'))) {
                this.loaderService.hide();
                this.router.navigate(['app-home'], {
                  queryParams: {
                    type: 'register',
                    status: true,
                    token: this.store.get('apiAccessToken'),
                    customerId: this.store.get('customerId'),
                  },
                });
                return;
              } else {
                this.router.navigate(['home']);
              }
            } else {
              this.toaster.error(res.message, 'Error!');
            }
            this.loaderService.hide();
            this.router.navigate(['home']);
          },
        });
    } else {
      this.toaster.error('Passcode is not match.', 'ERROR!');
    }
  }
}
