import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Icons } from 'src/app/constants/icons';
import { Lottie } from 'src/app/constants/lottie';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-investment-layout',
  templateUrl: './investment-layout.component.html',
  styleUrls: ['./investment-layout.component.scss'],
})
export class InvestmentLayoutComponent {
  icon: any;
  subscriptions: Subscription[] = [];
  isNavigationBarShow = true;
  mainTitle: any = '';

  constructor(
    public lottie: Lottie,
    private router: Router,
    public common: CommonService
  ) {
    this.icon = Icons;
  }
  backToHome() {
    this.router.navigate(['home']);
  }

  ngOnInit() {
    this.common.headerDetail$.next({ mainTitle: 'Investment' });

    // Add a subscription to headerDetail$ to update mainTitle
    this.subscriptions.push(
      this.common.headerDetail$.subscribe((headerDetail) => {
        if (headerDetail && headerDetail.mainTitle) {
          this.mainTitle = headerDetail.mainTitle;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
