interface MetaData {
    name?: string;
    property?: string;
    rel?: string;
    httpEquiv?: string;
    content?: string;
}

interface PageMeta {
    title: string;
    meta: MetaData[];
}

const logoUrl = 'https://credittnow.com/assets/gcloud-icon-0987654321/creditt_logo-light.svg';

export const homepage: PageMeta = {
    title: "Apply for Personal Loans Online from our WebApp | Creditt+",
    meta: [
        { name: 'description', content: 'Get instant access to personal loans online with Creditt+. Apply easily via our web app and enjoy quick disbursal of loans for salaried without paperwork.' },
        { property: 'og:title', content: 'Get Instant Loans with Creditt+ | Fast Approval & Disbursement' },
        { property: 'og:description', content: 'Apply for personal loans on Creditt+, a reliable platform offering real-time loan approval and quick disbursement.' },
        { property: 'og:url', content: 'https://credittnow.com/' },
        { name: 'robots', content: 'index, follow' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: "Apply for Personal Loans Online from our WebApp | Creditt+" },
        { name: 'twitter:description', content: 'Get instant access to personal loans online with Creditt+. Apply easily via our web app and enjoy quick disbursal of loans for salaried without paperwork.' },
        { name: 'twitter:image', content: logoUrl },
        { property: 'og:image', content: logoUrl },
        { rel: 'canonical', content: 'https://credittnow.com/',  }
    ]
};

export const repayment: PageMeta = {
    title: 'Instant Loan Repayment for Salaried Employees | Creditt+',
    meta: [
        { name: 'description', content: 'Simplify your finances with instant loan repayment options tailored for salaried employees at Creditt+. Experience hassle-free management of your loans today!' },
        { property: 'og:title', content: 'Instant Loan Repayment for Salaried Employees | Creditt+' },
        { property: 'og:description', content: 'Explore our FAQ section for answers to common questions about personal loans.' },
        { property: 'og:url', content: 'https://credittnow.com/faq' },
        { name: 'twitter:title', content: 'FAQ | Creditt+' },
        { name: 'twitter:description', content: 'Get the answers you need about our personal loans in the FAQ section.' },
        { name: 'twitter:image', content: logoUrl },
        { property: 'og:image', content: logoUrl },
        { rel: 'canonical', content: 'https://credittnow.com/faq',  }
    ]
};
