import { Component } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { HomeService } from '../home/home.service';
import { LoaderService } from 'src/app/services/loader.service';
import { StoreService } from 'src/app/services/store.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-app-home',
  templateUrl: './app-home.component.html',
  styleUrls: ['./app-home.component.scss'],
})
export class AppHomeComponent {
  constructor(
    public common: CommonService,
    public homeService: HomeService,
    public store: StoreService,
    public loaderService: LoaderService,
    public toastr: ToastrService
  ) {
    this.common.headerDetail$.next({ mainTitle: '' });
  }
  clickToRefresh() {
    window.location.reload();
  }

  ngOnInit() {
    this.openAppBtn();
  }

  openAppBtn() {
    this.loaderService.show();
    this.homeService.getDashboardData({ languageId: 'en' }).subscribe({
      next: (res: any) => {
        if (res.success && res.data) {
          this.common.openAndroidApp();
          this.common.customerNickName = res.data.nickName;
        } else {
          this.toastr.error(res.message, 'Error!');
        }
        this.loaderService.hide();
      },
    });
  }
}
