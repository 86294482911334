<div class="investment-app-layout-main isMoreLayout">
  <div class="investment-app-header">
    <app-header [title]="common.customerNickName" [isShowGreeting]="isNavigationBarShow"
      [isShowTitle]="isNavigationBarShow" [isShowLogoutButton]="false"></app-header>
  </div>
  <div class="investment-content-main isLoanStepMain" [ngClass]="{ 'navigation-bar-none': !isNavigationBarShow }">
    <div class="card-view main-card">
      <div class="img-class">
        <img [src]="icon.homeInvestIcon">
      </div>
      <div>
        <p class="p1">Investment</p>
      </div>
      <div>
        <p>Coming Soon</p>
      </div>
      <div>
        <p class="p1">AMFI#:ARN-247949</p>
      </div>
      <div class="btn-sec mt-3">
        <button [routerLink]="['/home']" class="card-view-button">
          Okay
        </button>
      </div>
    </div>
  </div>
</div>