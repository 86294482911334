<app-brand-logo></app-brand-logo>
<div class="card-view mobile-main">
  <div class="mobile-main-header">
    <div class="header-title">
      <p class="title">Let's Get Started</p>
      <p class="sub-title">Select Login Type</p>
    </div>
    <div class="mobile-main-icon">
      <img [src]="icon.loginIcon" />
    </div>
  </div>
  <div class="mobile-main-contain">
    <form [formGroup]="multiLoginForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <select
          class="form-select"
          aria-label="Default select example"
          formControlName="type"
          id="type"
          (change)="onTypeChange($event)"
        >
          <option value="" disabled selected>Login Type</option>
          <option value="1">Phone</option>
          <option value="2">Email</option>
          <option value="3">Loan ID</option>
        </select>

        <div class="mobile-main-contain">
          <input
            type="text"
            class="form-control"
            (keyup)="keyUpEvent($event)"
            formControlName="input"
            [placeholder]="'Enter Your' + ' ' + loginType"
            id="input"
          />
        </div>
      </div>
      <div class="button-div">
        <button
          type="submit"
          [disabled]="!multiLoginForm.valid || isDisable"
          class="card-view-button"
        >
          Continue
        </button>
        <!-- <button type="button" (click)="testDialog()" class="card-view-button">
          test
        </button> -->
      </div>
    </form>
  </div>
</div>
<!-- 
<div class="card-view mobile-main">
  <div class="mobile-main-header">
    <p>Lets begin!</p>
    <div class="mobile-main-icon">
      <img [src]="icon.mobile" />
    </div>
  </div>
  <div class="mobile-main-contain">
    <form [formGroup]="multiLoginForm" (ngSubmit)="onSubmit()">
      <style>
        .row {
          display: flex;
          align-items: center;
        }
        .form-group {
          margin: 0;
        }
      </style>

      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="type">Select Login Type:</label>
              <select
                class="form-select"
                aria-label="Default select example"
                formControlName="type"
                id="type"
                (change)="onTypeChange($event)"
              >
                <option value="" disabled selected>Select an option</option>
                <option value="1">Phone</option>
                <option value="2">Email</option>
                <option value="3">Loan ID</option>
              </select>
            </div>
          </div>
          <div class="col-md-9">
            <div class="form-group">
              <label for="input"></label>
              <input
                type="text"
                class="form-control"
                (keyup)="keyUpEvent($event)"
                formControlName="input"
                placeholder="Enter your Login Input"
                id="input"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="button-div">
        <button
          type="submit"
          [disabled]="!multiLoginForm.valid || isDisable"
          class="card-view-button"
        >
          Continue
        </button>
      </div>
    </form>
  </div>
</div> -->
