import { Component } from '@angular/core';
import { Icons } from 'src/app/constants/icons';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  icon: any;

  constructor() {
    this.icon = Icons;
  }
}
