import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const baseUrl = `${environment.baseUrl}credittScore`;

@Injectable({
  providedIn: 'root',
})
export class CredittScoreService {
  constructor(
    private http: HttpClient,
  ) { }

  creditScore() {
    return this.http.get<any>(`${baseUrl}`);
  }
}
