import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MarketingEventWhistleService {
  private resultSubject = new BehaviorSubject<any>({ success: true });
  public result$ = this.resultSubject.asObservable();

  constructor(private http: HttpClient) {}

  // Public method for triggering the API call
  pixelMarketingEventAPI(linkId: any, revenue: any, goalName: string) {
    const url = `${environment.pixelMarketingEventWhistleURL}?linkid=${linkId}&revenue=${revenue}&goal_name=${goalName}`;
    console.log('Request URL:', url);
    this.http.get<any>(url).subscribe(result => {
    },e=>{
    });
  };

  thoughverSetMarketingEventAPI(tid: any) {
    const url = `${environment.thoughverseMarketingURL}?m=12672&tid=${tid}`;
    console.log('Request URL:', url);
    this.http.get<any>(url).subscribe(result => {
    },e=>{
    });
  };

  affiliateAdsPlayTrackingEventAPI(orderId: any) {
    const url = `${environment.affiliateAdsPlayTrackingURL}?pgmid=3423586&orderId=${orderId}`;
    console.log('Request URL:', url);
    this.http.get<any>(url).subscribe(result => {
    },e=>{
    });
  };

  affiliateAdsPlayEventAPI() {
    const url = `${environment.affiliateAdsPlayURL}`;
    console.log('Request URL:', url);
    this.http.get<any>(url).subscribe(result => {
    },e=>{
    });
  };

  pruDigital99GoTrackierAPI(goal_value: any = '') {
    let url = `${environment.prudigital99APIURL}`;
    if (goal_value) {
      const separator = url.includes('?') ? '&' : '?';
      url = `${url}${separator}goal_value=${goal_value}`;
    }
    console.log('Request URL:', url);
    this.http.get<any>(url).subscribe(result => {
    },e=>{
    });
  };

  spectrumTrackierAPI(goal_value: any = '',click_id:any) {
    let url = `${environment.spectrumTrackierAPIURL}`;
    if (goal_value) {
      const separator = url.includes('?') ? '&' : '?';
      url = `${url}${separator}goal_value=${goal_value}`;
    };
    if(click_id){
      url = url.replace('{click_id}', click_id);
    }
    console.log('Request URL:', url);
    this.http.get<any>(url).subscribe(result => {
    },e=>{
    });
  }


}
