<div class="number-sec">
  <div class="passcode-header">
    <div class="icon-box">
      <img [src]="icon.passcodeIcon" />
    </div>
    <p class="title">
      {{ firstPasscode == "" ? "Create" : "Confirm" }} Passcode
    </p>
  </div>
  <div class="card-view">
    <div class="passCodeTitle">
      <div class="passcode-input">
        <div class="passcode-item" *ngFor="let digit of passcode; let i = index" [ngClass]="{ active: digit != '' }">
        </div>
      </div>
    </div>
    <div class="num-box">
      <span (click)="onClick('1')" [class.highlight]="numberKeypad[0]">1</span>
      <span (click)="onClick('2')" [class.highlight]="numberKeypad[1]">2</span>
      <span (click)="onClick('3')" [class.highlight]="numberKeypad[2]">3</span>
    </div>
    <div class="num-box">
      <span (click)="onClick('4')" [class.highlight]="numberKeypad[3]">4</span>
      <span (click)="onClick('5')" [class.highlight]="numberKeypad[4]">5</span>
      <span (click)="onClick('6')" [class.highlight]="numberKeypad[5]">6</span>
    </div>
    <div class="num-box">
      <span (click)="onClick('7')" [class.highlight]="numberKeypad[6]">7</span>
      <span (click)="onClick('8')" [class.highlight]="numberKeypad[7]">8</span>
      <span (click)="onClick('9')" [class.highlight]="numberKeypad[8]">9</span>
    </div>
    <div class="num-box num-box-last-row">
      <span class="hide-item"></span>
      <span (click)="onClick('0')" [class.highlight]="numberKeypad[9]">0</span>
      <span (click)="onClick('')" [class.highlight]="numberKeypad[10]">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="svgIcon" fill="currentColor"
          class="bi bi-backspace-fill" viewBox="0 0 16 16">
          <path
            d="M15.683 3a2 2 0 0 0-2-2h-7.08a2 2 0 0 0-1.519.698L.241 7.35a1 1 0 0 0 0 1.302l4.843 5.65A2 2 0 0 0 6.603 15h7.08a2 2 0 0 0 2-2V3zM5.829 5.854a.5.5 0 1 1 .707-.708l2.147 2.147 2.146-2.147a.5.5 0 1 1 .707.708L9.39 8l2.146 2.146a.5.5 0 0 1-.707.708L8.683 8.707l-2.147 2.147a.5.5 0 0 1-.707-.708L7.976 8 5.829 5.854z" />
        </svg></span>
    </div>
  </div>
</div>