<app-brand-logo></app-brand-logo>
<div class="card-view soi-card" *ngIf="isCalledApi">
  <p class="card-view-title">{{ inquiryQuestion?.question }}</p>
  <br />
  <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
    <div class="source-list">
      <label
        *ngFor="let source of sourceInquiryData; index as i"
        [for]="source.id"
        class="source-list-item"
        [ngClass]="{
          'source-list-item-border-bottom': sourceInquiryData.length - 1 != i
        }"
      >
        <div class="left">
          <img [src]="source.icon" alt="icon" />
          <p>{{ source.name }}</p>
        </div>
        <input
          type="radio"
          [id]="source.id"
          name="sourceId"
          formControlName="sourceId"
          [value]="source.id"
        />
      </label>
    </div>
    <button type="submit" class="card-view-button">Continue</button>
    <!-- <div class="text-link">
            <span class="text-link-data">*Loan processing is limited to loyal customer for now. New applicants, we are gearing up for you!</span>
        </div> -->
  </form>
</div>
