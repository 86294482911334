import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { StoreService } from '../services/store.service';

export const noAuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
):
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  console.log('::::call no auth');
  let getMobileAccessToken = inject(StoreService).get('mobileaccesstoken');
  let getToken = inject(StoreService).get('accessToken');
  let passcode = inject(StoreService).get('passcode');
  console.log('::getToken', getToken);
  console.log('::passcode', passcode);

  if (getToken != null) {
    if (passcode != null && passcode == 'true') {
      return inject(Router).createUrlTree(['/pass-code']);
    } else {
      return inject(Router).createUrlTree(['/home']);
    }
  } else {
    return true;
  }
};
