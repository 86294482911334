import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { StepRoutes } from './loan-layout.interface';

@Component({
  selector: 'app-loan-layout',
  templateUrl: './loan-layout.component.html',
  styleUrls: ['./loan-layout.component.scss'],
})
export class LoanLayoutComponent {
  isNavigationBarShow = true;
  stepRoutes = StepRoutes;

  constructor(public common: CommonService, private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isNavigationBarShow = !this.stepRoutes.includes(
          event.url.split('?')[0]
        );
      }
    });
  }
}
