import { Component, Input } from '@angular/core';
import { Icons } from 'src/app/constants/icons';
import { CommonService } from 'src/app/services/common.service';
import { LogoutComponent } from '../../loan-layout/dialogs/logout/logout.component';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { map, Subscription } from 'rxjs';
import { StoreService } from 'src/app/services/store.service';
import { NavigationStart, Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  icon: any;
  @Input() isShowBackButton = true;
  @Input() isShowGreeting = true;
  @Input() isShowTitle = true;
  @Input() isShowLogoutButton = true;
  @Input() title = '';
  @Input() greeting = this.getGreetingByTime();
  @Input() mainTitle = '';
  subscription!: Subscription;

  constructor(
    public common: CommonService,
    public dialog: MatDialog,
    private location: Location,
    public store: StoreService,
    private router: Router
  ) {
    this.icon = Icons;

    this.greeting = this.getGreetingByTime() || '';
    this.common.headerDetail$.next({ mainTitle: '' });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isShowBackButton = true;
        this.isShowGreeting = true;
        this.isShowTitle = true;
        this.isShowLogoutButton = true;
        this.title = this.store.get('customerNickName') || '';
        this.greeting = this.getGreetingByTime() || '';
        this.mainTitle = '';
      }
    });

    this.subscription = this.common.headerDetail$
      .pipe(
        map((res: any) => {
          if (res && res.mainTitle && res.mainTitle != '') {
            this.mainTitle = res?.mainTitle;
          }

          this.isShowBackButton =
            res?.isShowBackButton == false ? false : this.isShowBackButton;

          this.isShowGreeting =
            res?.isShowGreeting == false ? false : this.isShowGreeting;

          this.isShowTitle =
            res?.isShowTitle == false ? false : this.isShowTitle;

          this.isShowLogoutButton =
            res?.isShowLogoutButton == false ? false : this.isShowLogoutButton;

          this.title = res?.title || this.title;

          this.greeting = res?.greeting || this.greeting;
        })
      )
      .subscribe();
  }

  getGreetingByTime(): string {
    const currentTime = new Date();
    const currentHour = currentTime.getUTCHours() + 5; // Adding 5 hours to UTC time for IST
    if (currentHour >= 5 && currentHour < 12) {
      this.greeting = 'Good morning';
    } else if (currentHour >= 12 && currentHour < 17) {
      this.greeting = 'Good afternoon';
    } else {
      this.greeting = 'Good evening';
    }

    return this.greeting;
  }

  logout() {
    let width: string = window.innerWidth <= 768 ? '80%' : '30%';
    this.dialog.open(LogoutComponent, {
      width: width,
    });
  }

  goToBack() {
    const forBackToHome = ['/loan/apply', '/history/loans', '/more/info','/more/pass-code'];
    const currentRoute = this.router.url;
    if (forBackToHome.includes(currentRoute)) {
      this.router.navigate(['/home']);
    } else {
      this.location.back();
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
