import { environment } from 'src/environments/environment';

const theme = environment.theme == 'dark' ? 'dark' : 'light';

export const Icons = {
  logo: `../../../../assets/svg/theme/${theme}/logo.svg`,
  aadhar: `../../../../assets/svg/theme/${theme}/aadhar.svg`,
  security: `../../../../assets/svg/theme/${theme}/security_ic.svg`,
  moreInfo: `../../../../assets/svg/theme/${theme}/more_ic.svg`,
  locationLogo: `../../../../../assets/svg/theme/${theme}/location-logo.svg`,
  mobile: `../../../../../assets/svg/theme/${theme}/mobile.svg`,
  applyLoan: `../../../../assets/svg/theme/${theme}/apply-loan-icon.svg`,
  moreIcon: `../../../../assets/svg/theme/${theme}/more-info.svg`,
  defaultingLogo: `../../../../assets/svg/theme/${theme}/defaulting_logo.svg`,
  reportingLogo: `../../../../assets/svg/theme/${theme}/reporting_logo.svg`,
  financialLogo: `../../../../assets/svg/theme/${theme}/financial_logo.svg`,
  latePayment: `../../../../assets/svg/theme/${theme}/late-payment.svg`,
  reminderLogo: `../../../../assets/svg/theme/${theme}/reminder_logo.svg`,
  congratulationLogo: `../../../../assets/svg/theme/${theme}/congratulation-logo.svg`,
  mail: `../../../../assets/svg/theme/${theme}/mail.svg`,
  salarySlip: `../../../../assets/svg/theme/${theme}/salary-slip.svg`,
  employmentPending: `../../../../assets/svg/theme/${theme}/employment-pending.svg`,
  bankLogo: `../../../../assets/svg/theme/${theme}/bank-logo.svg`,
  right: `../../../../assets/svg/theme/${theme}/right_icon.svg`,
  decline: `../../../../assets/svg/theme/${theme}/decline_ic.svg`,
  process: `../../../../assets/svg/theme/${theme}/loan-history-process.svg`,
  successGreen: `../../../../assets/svg/theme/${theme}/green-icon.svg`,
  loanAmount: `../../../../assets/svg/theme/${theme}/loan_amount_ic.svg`,
  credittLoader: `../../../../assets/svg/theme/${theme}/creditt_loader.gif`,
  logout: `../../../../../assets/svg/theme/${theme}/logout.svg`,
  blackList: `../../../../../assets/svg/theme/${theme}/black-list.svg`,
  companyIcon: `../../../../../assets/svg/theme/${theme}/company-icon.svg`,
  paymentReminder: `../../../../../assets/svg/theme/${theme}/payment_reminders_ic.svg`,
  bureauReporting: `../../../../../assets/svg/theme/${theme}/bureau-reporting.svg`,
  defaultLoan: `../../../../../assets/svg/theme/${theme}/default-loan.svg`,
  malePlaceholder: `../../../../../assets/svg/theme/${theme}/male_placeholder.svg`,
  editIcon: `../../../../../assets/svg/theme/${theme}/edit_ic.svg`,
  panLogo: `../../../../../assets/svg/theme/${theme}/pan-card-logo.svg`,
  homeBorrowIcon: `../../../../../assets/svg/theme/${theme}/home_borrow_ic.svg`,
  homeInvestIcon: `../../../../../assets/svg/theme/${theme}/home_invest_ic.svg`,
  homeReferEarnIcon: `../../../../../assets/svg/theme/${theme}/home_refer_earn_ic.svg`,
  homeCredittScoreIcon: `../../../../../assets/svg/theme/${theme}/home_creditt_score_ic.svg`,
  credittSmallIcon: `../../../../../assets/svg/theme/${theme}/creditt_small_ic.svg`,
  aboutMore: `../../../../../assets/svg/theme/${theme}/about_more.svg`,
  repayment: `../../../../../assets/svg/theme/${theme}/repayment.svg`,
  mobileOtpIcon: `../../../../../assets/svg/theme/${theme}/mobile_otp_ic.svg`,
  backIcon: `../../../../../assets/svg/theme/${theme}/back_ic.svg`,
  homeIcon: `../../../../../assets/svg/theme/${theme}/home_ic.svg`,
  loanHistoryIcon: `../../../../../assets/svg/theme/${theme}/loan_history_ic.svg`,
  supportIcon: `../../../../../assets/svg/theme/${theme}/support_ic.svg`,
  moreInfoIcon: `../../../../../assets/svg/theme/${theme}/more_info_ic.svg`,
  passcodeIcon: `../../../../../assets/svg/theme/${theme}/passcode_ic.svg`,
  download: `../../../../../assets/svg/theme/${theme}/download.svg`,
  linkDownloadIc: `../../../../../assets/svg/theme/${theme}/link_download_ic.svg`,
  homeNormalIcon: `../../../../../assets/svg/theme/${theme}/home_normal_ic.svg`,
  loanHistoryNormalIcon: `../../../../../assets/svg/theme/${theme}/loan_history_normal_ic.svg`,
  supportNormalIcon: `../../../../../assets/svg/theme/${theme}/support_normal_ic.svg`,
  moreInfoNormalIcon: `../../../../../assets/svg/theme/${theme}/more_normal_ic.svg`,
  profileIcon: `../../../../../assets/svg/theme/${theme}/profile_ic.svg`,
  termsIcon: `../../../../../assets/svg/theme/${theme}/terms-conditions_ic.svg`,
  privacyIcon: `../../../../../assets/svg/theme/${theme}/privacy-policy_ic.svg`,
  inviteIcon: `../../../../../assets/svg/theme/${theme}/invite-friend_ic.svg`,
  referIcon: `../../../../../assets/svg/theme/${theme}/refer_earn_ic.svg`,
  feedbackIcon: `../../../../../assets/svg/theme/${theme}/feedback_ic.svg`,
  faqIcon: `../../../../../assets/svg/theme/${theme}/faq_ic.svg`,
  contactUsIcon: `../../../../../assets/svg/theme/${theme}/contact-us_ic.svg`,
  settingIcon: `../../../../../assets/svg/theme/${theme}/settings_ic.svg`,
  viewPartneredIcon: `../../../../../assets/svg/theme/${theme}/view-partnered_ic.svg`,
  logoutIcon: `../../../../../assets/svg/theme/${theme}/logout_ic.svg`,
  linkdenIcon: `../../../../../assets/svg/theme/${theme}/linkden_ic.svg`,
  facebookIcon: `../../../../../assets/svg/theme/${theme}/facebook_ic.svg`,
  instagramIcon: `../../../../../assets/svg/theme/${theme}/instagram_ic.svg`,
  contactIcon: `../../../../../assets/svg/theme/${theme}/contact_ic.svg`,
  grievanceIcon: `../../../../../assets/svg/theme/${theme}/grievance_ic.svg`,
  writeUsIcon: `../../../../../assets/svg/theme/${theme}/writeus_ic.svg`,
  maskIcon: `../../../../../assets/svg/theme/${theme}/mask_ic.svg`,
  pancardIcon: `../../../../../assets/svg/theme/${theme}/pan-card-logo.svg`,
  informationIcon: `../../../../../assets/svg/theme/${theme}/information-ic.svg`,
  sensitiveDataIcon: `../../../../../assets/svg/theme/${theme}/clear-sensitive-data-ic.svg`,
  loginIcon: `../../../../../assets/svg/theme/${theme}/login_ic.svg`,
  callIcon: `../../../../../assets/svg/theme/${theme}/call_ic.svg`,
  emailIcon: `../../../../../assets/svg/theme/${theme}/email_ic.svg`,
  referAndEarnIcon: `../../../../../assets/svg/theme/${theme}/home_refer_earn_ic.svg`,
  homeReferEarnInfoIcon: `../../../../../assets/svg/theme/${theme}/info_ic.svg`
};
