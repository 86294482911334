import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../auth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ToastrService } from 'ngx-toastr';
import { BlobService } from 'src/app/services/blob.service';
import { base64StringToBlob } from 'blob-util';

@Component({
  selector: 'app-selfie',
  templateUrl: './selfie.component.html',
  styleUrls: ['./selfie.component.scss']
})
export class SelfieComponent {
  readonly imageTrigger: Subject<void> = new Subject<void>();
  error?: string;
  webcamImage: string = '';
  imageUrl: string = '';
  width: number = 500;
  retakeImage: boolean = true;
  data:any;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    public authService: AuthService,
    public loaderService: LoaderService,
    public toastr: ToastrService,
    private blobService: BlobService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<SelfieComponent>
  ) {
    this.data = dialogData;
    console.log("::dialogData", dialogData);

    if (window.innerWidth >= 480 && window.innerWidth <= 768) {
      this.width = 250;           
    }
    else if (window.innerWidth <= 480) {
      this.width = 250;
    }
  }

  captureImage(webcamImage: WebcamImage): void {
    const contentType = 'image/jpeg'; // Change this based on your image format
    const blobImg = base64StringToBlob(webcamImage.imageAsBase64);
    
    this.imageUrl = webcamImage.imageAsDataUrl;
    this.retakeImage = false;
    if(this.data.type == 'retake'){
      this.reTake(blobImg);
    }
    else{
      this.profilePicture(blobImg);
    }
  }

  closeDialog = () => this.dialogRef.close('image.png');

  profilePicture(image: Blob) {
    if (image != null) {
      this.loaderService.show();
      this.authService.profilePicture({ image: image }).subscribe({
        next: (res: any) => {
          if (res.success && res.data) {
            this.dialogRef.close(res.data.user_profile);
          }
          else {
            this.toastr.error(res.message, 'Error!');
          }
          this.loaderService.hide();
        },
        error: (error: any) => {
          this.retakeImage = true;
          this.cdr.detectChanges();
        }
      });
    }
  }

  reTake(image: Blob) {
    if (image != null) {
      this.loaderService.show();
      this.authService.reTakeProfilePicture({ image: image }).subscribe({
        next: (res: any) => {
          console.log(":::::Res", res);
          if (res.success) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(['loan/apply']);
            });
            this.dialogRef.close('image.png');
          }
          else {
            this.toastr.error(res.message, 'Error!');
          }
          this.loaderService.hide();
        },
        error: (error: any) => {
          this.retakeImage = true;
          this.cdr.detectChanges();
        }
      });
    }
  }

  triggerSnapshot(): void {
    this.imageTrigger.next();
  }

  handleInitError(error: WebcamInitError): void {
    console.warn(error);
    this.error = JSON.stringify(error);
  }

  ngOnDestroy(): void {
    this.imageTrigger.complete();
  }
}
