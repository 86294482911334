<div class="dialog-card-view">
  <form (ngSubmit)="onSubmit()" [formGroup]="otpForm">
    <div class="card-header f-dir-column">
      <div class="icon-box">
        <img [src]="icon.mobileOtpIcon" />
      </div>
      <h4 class="header-title mb-0">
        {{
          type == 3
            ? "Enter Loan Id's register mobile number OTP!"
            : type == 2
            ? "Enter Mail OTP!"
            : "OTP has been sent to"
        }}
      </h4>
    </div>
    <div class="dialog-content m-0">
      <!-- <p>Mobile Number</p> -->
      <div class="number-edit-sec">
        <h4>
          {{
            type == 2 || type == 3
              ? input
              : "+91 " + (type ? input : userMobileNumber)
          }}
        </h4>
        <button (click)="editMobileNumber()" class="number-edit-btn">
          Edit
        </button>
      </div>

      <div class="otp-sec">
        <!-- <h4>A 6-digit OTP has been sent to your registered mobile number</h4>
      <p>(Don't forget to check your SPAM folder)</p> -->
        <div class="otp-main-contain">
          <form (ngSubmit)="onSubmit()" [formGroup]="otpForm">
            <div class="form-group">
              <!-- <label for="">Enter Otp</label> -->
              <div class="otp-main-contain-aadhar-input-group otp-box">
                <input
                  type="text"
                  inputmode="numeric"
                  *ngFor="let input of formInput; index as i"
                  #formRow
                  [formControlName]="input"
                  class="form-control"
                  (keyup)="keyUpEvent($event, i)"
                  maxlength="1"
                  placeholder="X"
                />
              </div>
            </div>
            <div class="dialog-btn-wrapper">
              <button
                type="submit"
                [disabled]="!otpForm.valid"
                class="card-view-button"
              >
                Continue
              </button>
            </div>
            <p class="spcl-text mtb-10" *ngIf="timeLeft != 0">
              Resend OTP (in {{ timeLeft }} seconds)
            </p>
            <p
              class="spcl-text cursor-pointer mtb-10"
              (click)="resendOtp()"
              *ngIf="timeLeft == 0"
            >
              Resend OTP
            </p>
          </form>
        </div>
      </div>
    </div>
  </form>
</div>
