<div class="dialog-card-view">
  <div class="card-header content-center f-dir-column">
    <div class="icon-box">
      <img [src]="icon.mail" />
    </div>
    <h4 class="header-title" *ngIf="!isSendOtp">Confirm Your Email</h4>
  </div>

  <div class="dialog-content m-0">
    <form (ngSubmit)="emailSubmit()" [formGroup]="emailVerificationForm">
      <div class="verify-email-wrapper">
        <!-- <label>Enter Email</label> -->
        <input
          type="text"
          class="form-control"
          *ngIf="!isSendOtp"
          autocomplete="false"
          formControlName="customerEmail"
          id="formGroupExampleInput"
          placeholder="e.g text@email.com"
        />

        <div class="change-email-wrapper" *ngIf="isSendOtp">
          <div class="email-info">
            <p class="info">An OTP has been sent to your email</p>
            <p class="info info-second">
              (Don't forget to check your SPAM folder)
            </p>
          </div>
          <div class="email-action">
            <p class="customer-email">{{ this.customerEnteredEmail }}</p>
            <p class="change-btn" (click)="changeEmail()">Edit</p>
          </div>
        </div>
        <div class="dialog-btn-wrapper" *ngIf="!isSendOtp">
          <button
            type="submit"
            [disabled]="!emailVerificationForm.valid"
            class="card-view-button"
          >
            Continue
          </button>
        </div>
      </div>
    </form>

    <div *ngIf="isSendOtp">
      <form (ngSubmit)="otpSubmit()" [formGroup]="otpForm">
        <div class="verify-email-wrapper">
          <div class="otp-main-section">
            <input
              type="text"
              inputmode="numeric"
              *ngFor="let input of formInput; index as i"
              #formRow
              [formControlName]="input"
              class="form-control otp-input"
              (keyup)="keyUpEvent($event, i)"
              maxlength="1"
              placeholder="X"
            />
          </div>

          <div class="dialog-btn-wrapper">
            <button
              type="submit"
              [disabled]="!otpForm.valid"
              class="card-view-button"
            >
              Continue
            </button>
          </div>
          <p class="resend-otp-time" *ngIf="timeLeft != 0">
            Resend OTP (in {{ timeLeft }} seconds)
          </p>
          <p class="resend-otp" (click)="resendOtp()" *ngIf="timeLeft == 0">
            Resend OTP
          </p>
        </div>
      </form>
    </div>
  </div>
</div>
