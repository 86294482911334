import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Icons } from 'src/app/constants/icons';

@Component({
  selector: 'app-location-info',
  templateUrl: './location-info.component.html',
  styleUrls: ['./location-info.component.scss']
})
export class LocationInfoComponent {
  icon:any;
  constructor(public dialog: MatDialog){
    this.icon = Icons;
  }
  continue(){
    this.dialog.closeAll();
  }
}
