<div class="dialog-card-view">
  <div class="card-header">
    <h4 class="header-title">Select Aadhaar!</h4>
  </div>
  <div class="dialog-content m-0">
    <form (ngSubmit)="onSubmit()" [formGroup]="aadharListForm">
      <div class="aadhar-list-contain-input-sec">
        <!-- <p class="input-label">Select Aadhaar Number</p> -->
        <label
          class="mainLabel"
          *ngFor="let aadhar of aadharList; let i = index"
          [for]="'aadharRadio_' + i"
        >
          <div class="input-item">
            <p>XXXX XXXX {{ aadhar.aadharLastDigits }}</p>
            <input
              type="radio"
              [id]="'aadharRadio_' + i"
              formControlName="aadharList"
              [checked]="i == 0"
              name="aadharList"
              [value]="aadhar.user_entry_id"
            />
          </div>
        </label>
      </div>

      <div class="dialog-btn-wrapper">
        <button
          type="submit"
          [disabled]="!aadharListForm.valid"
          class="card-view-button"
        >
          Continue
        </button>
      </div>
      <div class="change-number-wrapper">
        <p class="change-number-btn" (click)="changeNumber()">
          Change Mobile Number
        </p>
        <!-- <p class="add-adhaar-text" (click)="newAadhar()">Add a new Aadhaar</p> -->
      </div>
    </form>
  </div>
</div>
