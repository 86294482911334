import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './pipes/safePipe.component';
import { HeaderComponent } from './components/common/header/header.component';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideAnalytics, getAnalytics } from '@angular/fire/analytics';
import { NavigationBarComponent } from './components/common/navigation-bar/navigation-bar.component';
import { RouterModule } from '@angular/router';
import { BrandLogoComponent } from './components/common/brand-logo/brand-logo.component';

@NgModule({
  declarations: [
    SafePipe,
    HeaderComponent,
    NavigationBarComponent,
    BrandLogoComponent,
  ],
  exports: [
    SafePipe,
    HeaderComponent,
    NavigationBarComponent,
    BrandLogoComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AngularFireModule.initializeApp(environment.firebaseConfig), // Default Firebase configuration
    AngularFireModule.initializeApp(
      environment.firebaseEventConfig,
      'eventApp'
    ), // Event-specific Firebase configuration
    provideAnalytics(() => getAnalytics()),
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
