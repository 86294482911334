import { Injectable } from '@angular/core';
import { LoaderService } from './loader.service';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ESignDialogComponent } from '../components/loan-layout/esign/eSign-dialog/eSign-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private latitude$ = new BehaviorSubject<string>('');
  public latitude = this.latitude$.asObservable();

  private longitude$ = new BehaviorSubject<string>('');
  public longitude = this.longitude$.asObservable();

  private locationPermission$ = new BehaviorSubject<Boolean>(false);
  public locationPermission = this.locationPermission$.asObservable();

  constructor(public loaderService: LoaderService, public dialog: MatDialog) {}

  getCurrentLocation() {
    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Success handling
          console.log(
            '::::position.coords.latitude::',
            position.coords.latitude
          );
          console.log(
            '::::position.coords.longitude::',
            position.coords.longitude
          );

          if (
            position.coords.latitude != undefined &&
            position.coords.longitude != undefined
          ) {
            this.latitude$.next('' + position.coords.latitude);
            this.longitude$.next('' + position.coords.longitude);
          }
          return resolve(true);
        },
        (error) => {
          console.error('Error getting location:', error);
          return resolve(false);
        }
      );
    });
  }

  async checkPermission(): Promise<boolean> {
    return new Promise((resolve) => {
      this.loaderService.show();
      navigator.permissions &&
        navigator.permissions
          .query({ name: 'geolocation' })
          .then(async (PermissionStatus) => {
            this.dialog.closeAll();
            this.loaderService.hide();
            if (PermissionStatus.state == 'granted') {
              await this.getCurrentLocation();
              this.locationPermission$.next(true);
              return resolve(true);
            } else if (PermissionStatus.state == 'prompt') {
              await this.getCurrentLocation();
              this.locationPermission$.next(false);
              return resolve(false);
            } else {
              // in case of permission denied
              this.dialog.closeAll();
              let width: string = window.innerWidth <= 768 ? '90%' : '50%';
              this.dialog.open(ESignDialogComponent, {
                width: width,
                disableClose: true,
                data: {
                  hideSubmitBtn: true,
                  message:
                    'Location permission required. To provide the best experience, please ensure that location access is enabled in your browser settings.',
                },
              });
              this.locationPermission$.next(false);
              return resolve(false);
            }
          });
    });
  }
}
