import { Component } from '@angular/core';
import { Icons } from 'src/app/constants/icons';

@Component({
  selector: 'app-brand-logo',
  templateUrl: './brand-logo.component.html',
  styleUrls: ['./brand-logo.component.scss'],
})
export class BrandLogoComponent {
  icon: any;
  constructor() {
    this.icon = Icons;
  }
}
