<div class="dialog-card-view">
  <div class="card-header content-center f-dir-column">
    <div class="icon-box">
      <img [src]="icon.download" />
    </div>
    <p class="header-title">Install Web App</p>
  </div>

  <div class="dialog-content content-center mt-0">
    <p class="text-content text-center">
      Install our Application for quick access.
    </p>
  </div>
  <div class="dialog-btn-wrapper install-btn">
    <button class="secondary-btn card-view-button" (click)="skipInstall()">
      Skip
    </button>
    <button (click)="continue()" class="card-view-button card-button">
      Install
    </button>
  </div>
</div>
