import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SourceInquiryComponent } from './components/source-inquiry/source-inquiry.component';
import { authGuard } from './guards/auth.guard';
import { noAuthGuard } from './guards/no-auth.guard';
import { HomeComponent } from './components/home/home.component';
import { InvestmentLayoutComponent } from './components/investment-layout/investment-layout.component';
import { PasscodeComponent } from './components/auth/passcode/passcode.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    // canActivate: [noAuthGuard],
    // canActivateChild: [noAuthGuard],
    path: 'auth',
    loadChildren: () =>
      import('./components/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'auth/app',
    loadChildren: () =>
      import('./components/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'auth/pass-code',
    component : PasscodeComponent
  },
  {
    canActivate: [noAuthGuard],
    path: 'source-inquiry',
    component: SourceInquiryComponent,
  },
  {
    path: 'more',
    loadChildren: () =>
      import('../app/components/more-info/more-info.module').then(
        (m) => m.MoreInfoModule
      ),
  },
  {
    canActivate: [authGuard],
    canActivateChild: [authGuard],
    path: 'loan',
    loadChildren: () =>
      import('./components/loan-layout/loan-layout.module').then(
        (m) => m.LoanLayoutModule
      ),
  },
  {
    canActivate: [authGuard],
    canActivateChild: [authGuard],
    path: 'history',
    loadChildren: () =>
      import('./components/loan-history/loan-history.module').then(
        (m) => m.LoanHistoryModule
      ),
  },
  {
    path: 'home',
    canActivate: [authGuard],
    canActivateChild: [authGuard],
    component: HomeComponent,
  },
  {
    path: 'investment',
    canActivate: [authGuard],
    canActivateChild: [authGuard],
    component: InvestmentLayoutComponent,
  },
  {
    path: 'pass-code',
    canActivate: [authGuard],
    canActivateChild: [authGuard],
    component: PasscodeComponent,
  },
  {
    path: 'creditt-score',
    loadChildren: () =>
      import('./components/creditt-score/creditt-score.module').then(
        (m) => m.CredittScoreModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
