import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PwaInstallService {
  private deferredPrompt: any;
  public showInstallButton = false;

  constructor() {
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      this.deferredPrompt = event;
      this.showInstallButton = !this.isInstalled();
    });

    window.addEventListener('appinstalled', () => {
      this.showInstallButton = false;
    });
  }

  promptInstall(): void {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult: any) => {
        console.log(choiceResult, ':::::::::');

        this.deferredPrompt = null;
        this.showInstallButton = false;
      });
    }
  }

  isInstalled(): boolean {
    const isStandaloneDisplayMode = window.matchMedia(
      '(display-mode: standalone)'
    ).matches;
    const isStandaloneNavigator = (window.navigator as any).standalone === true;

    return isStandaloneDisplayMode || isStandaloneNavigator;
  }
}
