import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Icons } from 'src/app/constants/icons';
import { LocationInfoComponent } from '../location-info/location-info.component';

@Component({
  selector: 'app-info-message',
  templateUrl: './info-message.component.html',
  styleUrls: ['./info-message.component.scss'],
})
export class InfoMessageComponent {
  icon: any;
  constructor(public dialog: MatDialog) {
    this.icon = Icons;
  }
  continue() {
    this.dialog.closeAll();
    let width: string = window.innerWidth <= 768 ? '90%' : '40%';
    this.dialog.open(LocationInfoComponent, {
      width: width,
      disableClose: true,
    });
  }
}
