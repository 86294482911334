import { Component, ViewChildren } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../auth.service';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/services/loader.service';
import { Icons } from 'src/app/constants/icons';
import { MatDialog } from '@angular/material/dialog';
import { AadharOtpComponent } from '../dialogs/aadhar-otp/aadhar-otp.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CryptoEncryptionService } from 'src/app/services/crypto-encryption.service';
import { StoreService } from 'src/app/services/store.service';
@Component({
  selector: 'app-aadhar',
  templateUrl: './aadhar.component.html',
  styleUrls: ['./aadhar.component.scss'],
})
export class AadharComponent {
  aadharForm!: FormGroup;
  icon: any;

  constructor(
    private authService: AuthService,
    public toastr: ToastrService,
    public loaderService: LoaderService,
    public dialog: MatDialog,
    public encrypt: CryptoEncryptionService,
    private router: Router,
    private route: ActivatedRoute,
    private store: StoreService
  ) {
    this.aadharForm = this.toFormGroup(this.formInput);
    this.icon = Icons;
  }

  formInput = ['aadhar1', 'aadhar2', 'aadhar3'];
  @ViewChildren('formRow') rows: any;
  aadharAuthType: 'KYC' | 'REKYC' | '' = '';

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      console.log('::Object.entries(params)', Object.entries(params).length);
      if (Object.entries(params).length == 1) {
        console.log('::params', params);
        let paramsValue = this.encrypt.decryptionAES(
          params[Object.keys(params)[0]]
        );
        console.log('::paramsValue', paramsValue);

        if (paramsValue == 'KYC' || paramsValue == 'REKYC') {
          this.aadharAuthType = paramsValue;
        }
      } else {
        this.router.navigate(['auth/mobile']);
      }
    });
  }

  ngAfterViewInit() {
    this.rows._results[0].nativeElement.focus();
  }

  toFormGroup(elements: any) {
    const group: any = {};
    elements.forEach((key: any) => {
      group[key] = new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(4),
        Validators.maxLength(4),
      ]);
    });
    return new FormGroup(group);
  }

  keyUpEvent(event: any, index: any) {
    const validCharacters = /^[0-9]*$/;
    if (
      this.rows._results[index].nativeElement.value.length == 4 ||
      (event.keyCode === 8 &&
        event.which === 8 &&
        this.rows._results[index].nativeElement.value.length == 0)
    ) {
      let pos = index;
      if (event.keyCode === 8 && event.which === 8) {
        pos = index - 1;
      } else {
        pos = index + 1;
      }
      if (pos > -1 && pos < this.formInput.length) {
        if (
          validCharacters.test(this.rows._results[index].nativeElement.value) ||
          (event.keyCode === 8 && event.which === 8)
        ) {
          this.rows._results[pos].nativeElement.focus();
        } else {
          this.rows._results[index].nativeElement.value = '';
        }
      }
    } else {
      if (
        validCharacters.test(this.rows._results[index].nativeElement.value) ||
        (event.keyCode === 8 && event.which === 8)
      ) {
      } else {
        this.rows._results[index].nativeElement.value = '';
      }
    }
  }

  differentMobile() {
    this.router.navigate(['auth/mobile']);
  }

  onSubmit() {
    if (
      this.aadharForm.value.aadhar1 != '' &&
      this.aadharForm.value.aadhar2 != '' &&
      this.aadharForm.value.aadhar3 != '' &&
      this.aadharAuthType != ''
    ) {
      this.loaderService.show();
      let userEntryId = this.store.get('userEntryId');
      let aadharNumber =
        this.aadharForm.value.aadhar1 +
        this.aadharForm.value.aadhar2 +
        this.aadharForm.value.aadhar3;
      this.authService
        .registerAadharSendOtp({
          aadharNumber: aadharNumber,
          type: this.aadharAuthType,
          userEntryId: userEntryId || '',
        })
        .subscribe({
          next: (res: any) => {
            if (res.success) {
              let width: string = window.innerWidth <= 768 ? '90%' : '35%';
              this.dialog.open(AadharOtpComponent, {
                width: width,
                disableClose: true,
                data: {
                  customerId: aadharNumber,
                  aadharAuthType: this.aadharAuthType,
                },
              });
            } else {
              this.toastr.error(res.message, 'Error!');
            }
            this.loaderService.hide();
          },
          error: (err: any) => {
            this.loaderService.hide();
          },
        });
    }
  }
}
