<div class="dialog-card-view">
  <div class="card-header f-dir-column">
    <div class="icon-box">
      <img [src]="icon.mobileOtpIcon" alt="" srcset="" />
    </div>
    <p class="header-title mb-0">Enter Aadhaar OTP</p>
  </div>

  <form (ngSubmit)="onSubmit()" [formGroup]="otpForm">
    <div class="dialog-content m-0">
      <div class="form-group">
        <!-- <label for="">Enter Otp</label> -->
        <div class="otp-box">
          <input
            type="text"
            inputmode="numeric"
            *ngFor="let input of formInput; index as i"
            #formRow
            [formControlName]="input"
            class="form-control"
            (keyup)="keyUpEvent($event, i)"
            maxlength="1"
            placeholder="X"
          />
        </div>
      </div>
    </div>
    <div class="dialog-btn-wrapper">
      <button
        type="submit"
        [disabled]="!otpForm.valid"
        class="card-view-button card-button"
      >
        Continue
      </button>
    </div>
  </form>
  <div class="re-send-wrapper">
    <p class="spcl-text" *ngIf="timeLeft != 0">
      Resend OTP (in {{ timeLeft }} seconds)
    </p>
    <p
      class="spcl-text cursor-pointer"
      (click)="resendOtp()"
      *ngIf="timeLeft == 0"
    >
      Resend OTP
    </p>
  </div>
</div>
