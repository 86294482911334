import { Component } from '@angular/core';
import { SourceInquiryService } from './source-inquiry.service';
import { InquiryData, SourceInquiry } from './source-inquiry.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/services/loader.service';
import { StoreService } from 'src/app/services/store.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
// import { Analytics, logEvent ,setUserProperties} from '@angular/fire/analytics';

@Component({
  selector: 'app-source-inquiry',
  templateUrl: './source-inquiry.component.html',
  styleUrls: ['./source-inquiry.component.scss'],
})
export class SourceInquiryComponent {
  public sourceInquiryData: any;
  public inquiryQuestion: SourceInquiry | undefined;
  isSubmitted: boolean = false;
  registrationForm: FormGroup;

  constructor(
    private sourceInquiryService: SourceInquiryService,
    public fb: FormBuilder,
    private router: Router,
    public toastr: ToastrService,
    public loaderService: LoaderService,
    private store: StoreService,
    private analytics: AnalyticsService
  ) {
    this.registrationForm = this.fb.group({
      sourceId: null,
    });
  }

  ngOnInit() {
    this.getSourceInquiry();
  }

  isCalledApi: boolean = false;
  getSourceInquiry() {
    this.loaderService.show();
    this.sourceInquiryService.fetchSourceInquiry().subscribe({
      next: (res: any) => {
        if (res.success) {
          this.inquiryQuestion = {
            q_id: res.data[0].q_id,
            question: res.data[0]?.question,
          };
          this.sourceInquiryData = res.data[0]?.options;
        }
        else {
          this.toastr.error(res.message, 'Error!');
        }
        this.isCalledApi = true;
        this.loaderService.hide();
      },
      error: (err: any) => {
        this.isCalledApi = true;
        this.loaderService.hide();
        this.toastr.error(err.error.message, 'Error!');
      }
    });
  }

  onSubmit() {
    if (!this.registrationForm.valid) {
      return false;
    } else {
      if (this.registrationForm.value.sourceId != null) {
        this.loaderService.show();
        let data: InquiryData = {
          a_id: this.registrationForm.value.sourceId,
          q_id: this.inquiryQuestion?.q_id
        }
        let sendAnalyticsObj = {
          answerId: this.registrationForm.value.sourceId,
          q_id: this.inquiryQuestion?.q_id,
          customerId: this.store.get('customerId'),
          loanId: this.store.get('loanId')
        };
        this.analytics.sendDataToGoogleAnalytics('web_source_of_inquery_submit', sendAnalyticsObj);
        this.sourceInquiryService
          .submitSourceInquiry([data])
          .subscribe({
            next: (res: any) => {
              if (res.success) {
                this.store.set({ key: 'source_inquiry_id', value: "" + res.data.ansID });
                this.router.navigate(['auth/mobile']);
              }
              else {
                this.toastr.error(res.message, 'Error!');
              }
              this.loaderService.hide();
            },
            error: (err: any) => {
              this.loaderService.hide();
              this.toastr.error(err.error.message, 'Error!');
            },
          });

        return true;
      }
      else {
        this.toastr.error('Please select source inquiry', 'Error!');
        return false;
      }
    }
  }
}

