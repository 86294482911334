import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FirebaseMessagingService {
  private tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(private afMessaging: AngularFireMessaging) {
    this.initializeToken();
  }

  private initializeToken() {
    this.afMessaging.requestToken.pipe(
      tap(token => {
        console.log('FCM Token:', token);
        this.tokenSubject.next(token);
      }),
      catchError(error => {
        console.error('Error getting FCM token:', error);
        this.tokenSubject.error(error);
        return [];
      })
    ).subscribe();
  }

  getToken() {
    return new Promise((resolve) => {
        this.tokenSubject.subscribe({
            next: (token: any) => {
                if(token) resolve(token);
            },
            error: (error: any) => {
                resolve('')
            }
      });
    });
  }

  getMessages(): Observable<any> {
    return this.afMessaging.messages;
  }
}