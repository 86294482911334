import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SourceInquiryComponent } from './components/source-inquiry/source-inquiry.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderComponent } from './components/loader/loader.component';
import { MatDialogModule } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { LoanLayoutComponent } from './components/loan-layout/loan-layout.component';

import { LottieModule } from 'ngx-lottie';
import { SharedModule } from './shared.module';
import { VideoRecordingService } from './services/video.service';
import { AuthModule } from './components/auth/auth.module';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { HomeComponent } from './components/home/home.component';
import { InvestmentLayoutComponent } from './components/investment-layout/investment-layout.component';
import { AppHomeComponent } from './components/app-home/app-home.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularFireModule } from '@angular/fire/compat';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { FlyyWebComponent } from './components/flyy-web/flyy-web.component';
import { MatTooltipModule} from '@angular/material/tooltip';
// import { playerFactory } from './lottie.factory'; // Import playerFactory

export function playerFactory(): any {
  return import('lottie-web');
}
@NgModule({
  declarations: [
    AppComponent,
    SourceInquiryComponent,
    LoanLayoutComponent,
    LoaderComponent,
    LoadingScreenComponent,
    HomeComponent,
    InvestmentLayoutComponent,
    AppHomeComponent,
    FlyyWebComponent
  ],
  imports: [
    BrowserModule,
    AuthModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig, 'default'),
    provideFirebaseApp(() =>
      initializeApp(environment.firebaseConfig, 'default')
    ),
    AngularFireMessagingModule,
    MatDialogModule,
    SharedModule,
    LottieModule.forRoot({ player: playerFactory }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    VideoRecordingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
