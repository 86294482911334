import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Icons } from 'src/app/constants/icons';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent {
  icon: any;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    public store: StoreService
  ) {
    this.icon = Icons;
  }
  yes() {
    this.store.clearAuthStore();
    this.router.navigate(['auth/mobile']);
  }
  no() {
    this.dialog.closeAll();
  }
}
