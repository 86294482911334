import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StoreService } from '../services/store.service';

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
):
  Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
    console.log(":::::auth gard call");
    
  let getToken = inject(StoreService).get('accessToken');
  console.log(":::::getToken::", getToken);
  
  if (getToken != null) {
    return true;
  }
  else {
    return inject(Router).createUrlTree(['/auth/mobile']);
  }
}
