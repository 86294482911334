import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { AadharComponent } from './aadhar/aadhar.component';
import { MobileComponent } from './mobile/mobile.component';
import { MultiLoginComponent } from './multi-login/multi-login.component';
import { AppHomeComponent } from './../app-home/app-home.component';
import { RegisterComponent } from './register/register.component';
import { PasscodeComponent } from './passcode/passcode.component';
import { noAuthGuard } from 'src/app/guards/no-auth.guard';
import { LoadingScreenComponent } from 'src/app/loading-screen/loading-screen.component';
const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    canActivate: [noAuthGuard],
    path: '',
    component: LoadingScreenComponent,
  },
  {
    canActivate: [noAuthGuard],
    path: 'mobile',
    component: MobileComponent,
  },
  {
    path: 'aadhar',
    component: AadharComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'app',
    component: MobileComponent,
  },
  {
    path: 'repayment',
    component: MultiLoginComponent,
  },
  {
    path: 'app-home',
    component: AppHomeComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
