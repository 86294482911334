import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InquiryData, SourceInquiry } from './source-inquiry.interface';

const baseUrl = `${environment.baseUrl}sourceOfInquiry`;
@Injectable({
  providedIn: 'root'
})
export class SourceInquiryService {

  constructor(private http: HttpClient) { }

  fetchSourceInquiry() {
    return this.http.get<SourceInquiry[]>(`${baseUrl}/getSourceQuestion`);
  }

  submitSourceInquiry(inquiryData: InquiryData[]) {
    return this.http.post<InquiryData[]>(`${baseUrl}/submitSourceQuestions`, {data: inquiryData});
  }
}
