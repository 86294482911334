<app-brand-logo></app-brand-logo>
<div class="card-view main-card">
  <div class="card-view-title">
    <h4>Profile</h4>
  </div>

  <div class="user-image">
    <div class="img-preview">
      <img [src]="imageUrl" alt="" srcset="" />
      <div class="edit-image" (click)="openCamera()">
        <label for="choose-file"
          ><img [src]="icon.editIcon" alt="" srcset=""
        /></label>
      </div>
    </div>
  </div>

  <div class="register-form-sec">
    <form (ngSubmit)="onSubmit()" [formGroup]="registerForm">
      <div class="input-group">
        <div class="input-group-item">
          <label for="customerName" class="form-label"
            >Name (as per Aadhaar)</label
          >
          <input
            type="text"
            readonly
            formControlName="customerName"
            class="form-control"
            id="customerName"
            placeholder="Enter Your Name (as per Aadhaar)"
          />
        </div>
        <div class="input-group-item">
          <label for="customerMobile" class="form-label">Mobile number</label>
          <input
            type="text"
            readonly
            formControlName="customerMobile"
            class="form-control"
            id="customerMobile"
            placeholder="Enter Your mobile number"
          />
        </div>
      </div>
      <div class="input-group">
        <div class="input-group-item">
          <label for="customerEmail" class="form-label">Email Id</label>
          <input
            type="email"
            (click)="verifyEmail()"
            formControlName="customerEmail"
            class="form-control"
            id="customerEmail"
            readonly
            placeholder="Enter Your email id"
          />
        </div>
        <div class="input-group-item">
          <label for="customerDob" class="form-label"
            >Date of Birth <i class="fa fa-birthday-cake" aria-hidden="true"></i
          ></label>
          <input
            type="text"
            readonly
            formControlName="customerDob"
            class="form-control"
            id="customerDob"
            placeholder=""
          />
        </div>
      </div>
      <div class="input-group">
        <div class="input-group-item">
          <label class="form-label">Gender</label>
          <input
            type="text"
            readonly
            formControlName="customerGender"
            class="form-control"
            id="customerGender"
          />
        </div>
        <div class="input-group-item">
          <label for="MonthlyTakeHomeSalary" class="form-label"
            >Monthly take-home salary</label
          >
          <input
            (keyup)="salaryOnChange($event)"
            minlength="4"
            autocomplete="off"
            maxlength="7"
            formControlName="MonthlyTakeHomeSalary"
            type="text"
            class="form-control"
            id="MonthlyTakeHomeSalary"
            placeholder="Enter Your Monthly take-home salary"
          />
        </div>
        
      </div>
      <div class="input-group">
        <div class="input-group-item-textarea">
          <label for="customerAddress" class="form-label"
            >Address (as per Aadhaar)</label
          >
          <textarea
            formControlName="customerAddress"
            readonly
            type="text-area"
            class="form-control textarea-input"
            id="customerAddress"
            placeholder="Enter Your Address (as per Aadhaar)"
          ></textarea>
        </div>
      </div>
      <div class="input-group-item">
        <label for="MonthlyTakeHomeSalary" class="form-label"
            >Referral Code</label
          >
        <input
          autocomplete="off"
          formControlName="referralCode"
          type="text"
          class="form-control"
          [(ngModel)]="referralCode"
          id="referralCode"
          placeholder="Enter Referral Code (Optional)" [readonly]="disableReferralInput"
        />
      </div>
      <div class="form-check">
        <input
          formControlName="confirmCheckBox"
          class="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckDefault"
          (click)="onCheckboxClick($event)"
        />
        <p>
          The lender, on borrower's consent would reach out to the alternative
          contacts if the borrower is non-contactable, unresponsive, refusing
          and or denying to make the payment
        </p>
      </div>
      <div class="register-btn">
        <button
          type="submit"
          [disabled]="!registerForm.valid || isSubmitting"
          class="card-view-button"
        >
          Verify
        </button>
      </div>
    </form>
  </div>
</div>
