import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Icons } from 'src/app/constants/icons';

@Component({
  selector: 'app-eSign-dialog',
  templateUrl: './eSign-dialog.component.html',
  styleUrls: ['./eSign-dialog.component.scss'],
})
export class ESignDialogComponent {
  icon: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ESignDialogComponent>,
    public router: Router
  ) {
    this.icon = Icons;
  }

  handleClick() {
    const event = new CustomEvent('dialog-clicked', { detail: 'focus-window' });
    window.dispatchEvent(event);
  }

  close() {
    const event = new CustomEvent('close-all-windows');
    window.dispatchEvent(event);

    this.dialogRef.close();
    this.router.navigate(['loan/apply']);
  }
}
