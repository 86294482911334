import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  CheckUser,
  GetAccessToken,
  Register,
  RegisterAadharSendOtp,
  RegisterAadharVerifyOtp,
  SendEmailOtp,
  SetPasscode,
  saveFCMToken,
  UploadProfile,
  VerifyEmailOtp,
  VerifyOtp,
  registerMobile,
  registerVerifyOtp,
  authRepayment
} from './auth.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  baseUrl = `${environment.baseUrl}auth`;
  constructor(private http: HttpClient) { }

  registerMobile(req: registerMobile) {
    return this.http.post<any>(`${this.baseUrl}/registerOrLogin`, req);
  }
  authRepayment(req: authRepayment) {
    return this.http.post<any>(`${this.baseUrl}/repayment`, req);
  }
  registerVerifyOtp(req: registerVerifyOtp) {
    return this.http.post<any>(`${this.baseUrl}/verifyOtp`, req);
  }
  registerSelectUser(userId: string) {
    return this.http.get<any>(`${this.baseUrl}/registerSelectUser/${userId}`);
  }
  getRegisterProfile() {
    return this.http.get<any>(`${this.baseUrl}/getRegisterProfile`);
  }
  registerAadharSendOtp(req: RegisterAadharSendOtp) {
    return this.http.post<any>(`${this.baseUrl}/registerAadharSendOtp`, req);
  }
  registerAadharVerifyOtp(req: RegisterAadharVerifyOtp) {
    return this.http.post<any>(`${this.baseUrl}/registerAadharVerifyOtp`, req);
  }
  profilePicture(req: UploadProfile) {
    const formData = new FormData();
    formData.append("image", req.image, 'profile.jpeg');
    return this.http.post<any>(`${this.baseUrl}/verify/profilePicture`, formData);
  }
  reTakeProfilePicture(req: UploadProfile) {
    const formData = new FormData();
    formData.append("image", req.image, 'profile.jpeg');
    return this.http.post<any>(`${this.baseUrl}/reTakeProfile`, formData);
  }
  sendEmailOtp(req: SendEmailOtp) {
    return this.http.post<any>(`${this.baseUrl}/registerEmailSendOtp`, req);
  }
  verifyEmailOtp(req: VerifyEmailOtp) {
    return this.http.post<any>(`${this.baseUrl}/registerEmailVerifyOtp`, req);
  }
  register(req: Register) {
    return this.http.post<any>(`${this.baseUrl}/registerMain`, req);
  }
  setPasscode(req: SetPasscode) {
    return this.http.post<any>(`${this.baseUrl}/setPasscode`, req);
  }
  saveFCMToken(req: saveFCMToken) {
    return this.http.post<any>(`${this.baseUrl}/fcm-token`, req);
  }
  checkUser(req: CheckUser) {
    return this.http.post<any>(`${this.baseUrl}/login`, req);
  }

  verifyUserOtp(req: VerifyOtp) {
    return this.http.post<any>(`${this.baseUrl}/verify/otp`, req);
  }

  getAccessToken(req: GetAccessToken) {
    return this.http.post<any>(`${this.baseUrl}/generate/access`, req);
  }

  

  
}
