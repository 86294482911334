<div class="dialog-card-view">
  <div class="card-header content-center f-dir-column">
    <div class="icon-box">
      <img [src]="icon.mobile" />
    </div>
  </div>
  <div class="dialog-content content-center mt-0">
    <p class="text-content text-center mtb-10">
      Customers who apply for a loan through mobile browser have a higher
      approval rate. Consider switching for a better chance!
    </p>
    <div class="dialog-btn-wrapper">
      <button (click)="continue()" class="card-view-button fit-btn">
        Okay
      </button>
    </div>
  </div>
</div>
