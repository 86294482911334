import { NgModule } from '@angular/core';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import { AadharComponent } from './aadhar/aadhar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { InfoMessageComponent } from './dialogs/info-message/info-message.component';
import { LocationInfoComponent } from './dialogs/location-info/location-info.component';
import { MobileComponent } from './mobile/mobile.component';
import { RegisterComponent } from './register/register.component';
import { AadharListComponent } from './dialogs/aadhar-list/aadhar-list.component';
import { AadharOtpComponent } from './dialogs/aadhar-otp/aadhar-otp.component';
import { MobileOtpComponent } from './dialogs/mobile-otp/mobile-otp.component';
import { SelfieComponent } from './dialogs/selfie/selfie.component';
import { WebcamModule } from 'ngx-webcam';
import { EmailVerificationComponent } from './dialogs/email-verification/email-verification.component';
import { MultiLoginComponent } from './multi-login/multi-login.component';
import { SharedModule } from '../../shared.module';
import { LottieModule } from 'ngx-lottie';
export function playerFactory(): any {
  return import('lottie-web');
}

@NgModule({
  declarations: [
    AuthComponent,
    AadharComponent,
    InfoMessageComponent,
    LocationInfoComponent,
    MobileComponent,
    RegisterComponent,
    AadharListComponent,
    AadharOtpComponent,
    MobileOtpComponent,
    SelfieComponent,
    EmailVerificationComponent,
    MultiLoginComponent,
  ],
  imports: [
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    WebcamModule,
    SharedModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
})
export class AuthModule {}
