<app-brand-logo class="mobile-logo isMobileViewLogo"></app-brand-logo>
<div class="auth-container isLoginMain">
  <div class="login-image-main">
    <ng-lottie class="lottie-img" [options]="lottie.login"></ng-lottie>
  </div>
  <div class="card-view mobile-main">
    <div class="brand-logo logo-none">
      <app-brand-logo class="logo"></app-brand-logo>
    </div>
    <div class="mobile-main-header">
      <div class="header-title">
        <p class="title">Let's Get Started</p>
        <p class="sub-title">Login With Phone Number</p>
      </div>
      <div class="mobile-main-icon">
        <img [src]="icon.loginIcon" />
      </div>
    </div>
    <div class="mobile-main-contain">
      <form [formGroup]="mobileForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="mobile-main-contain">
            <input
              type="text"
              inputmode="numeric"
              class="form-control"
              (keyup)="keyUpEvent($event)"
              formControlName="mobile"
              minlength="10"
              autocomplete="off"
              maxlength="10"
              placeholder="Enter your Mobile Number"
            />
          </div>
        </div>
        <div class="button-div">
          <button
            type="submit"
            [disabled]="!mobileForm.valid || isDisable"
            class="card-view-button"
          >
            Continue
          </button>
          <!-- <button type="button" (click)="testDialog()" class="card-view-button">
        test
      </button> -->
        </div>
        <div class="install-sec" *ngIf="showInstallButton">
          <img [src]="icon.linkDownloadIc" alt="" />
          <p>
            Install Web App
            <span class="link-text" (click)="installApplication()"
              >Click Here
            </span>
          </p>
        </div>
      </form>
    </div>
  </div>
</div>
