<div class="card-view main-card">
  <p class="close-dialog-btn"><span (click)="closeDialog()">&#10005;</span></p>
  <webcam
    [allowCameraSwitch]="true"
    [videoOptions]="{ facingMode: 'user' }"
    *ngIf="!error && retakeImage"
    [width]="width"
    [trigger]="imageTrigger"
    (imageCapture)="captureImage($event)"
    (initError)="handleInitError($event)"
    class="camera-sec"
  ></webcam>
  <img
    [src]="imageUrl"
    *ngIf="!retakeImage"
    class="camera-sec"
    alt=""
    srcset=""
  />
  <div class="action-sec" *ngIf="retakeImage">
    <div class="selfie-info">
      <h4>Capture Selfie</h4>
      <p>Ensure your entire face fits in the frame</p>
    </div>
    <div class="take-selfie-btn">
      <button (click)="triggerSnapshot()"></button>
    </div>
  </div>
</div>
