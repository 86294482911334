import { Component, ViewChildren } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Icons } from 'src/app/constants/icons';
import { LoaderService } from 'src/app/services/loader.service';
import { AuthService } from '../../auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
})
export class EmailVerificationComponent {
  emailVerificationForm!: FormGroup;
  otpForm!: FormGroup;
  icon: any;
  isSendOtp: boolean = false;
  customerEnteredEmail: string = '';

  formInput = ['otp1', 'otp2', 'otp3', 'otp4', 'otp5', 'otp6'];
  @ViewChildren('formRow') rows: any;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EmailVerificationComponent>,
    private loaderService: LoaderService,
    private authService: AuthService,
    private toastr: ToastrService
  ) {
    this.icon = Icons;
    this.otpForm = this.toFormGroup(this.formInput);
  }

  ngOnInit() {
    this.emailVerificationForm = this.fb.group({
      customerEmail: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
    });
  }

  timeLeft: number = 0;
  interval: any;
  startTimer() {
    this.timeLeft = 60;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }
  resendOtp() {
    this.startTimer();
    this.emailSubmit();
    this.otpForm.patchValue({
      otp1: '',
      otp2: '',
      otp3: '',
      otp4: '',
      otp5: '',
      otp6: '',
    });
  }
  changeEmail() {
    this.isSendOtp = false;
    this.customerEnteredEmail = '';
    this.emailVerificationForm.patchValue({
      customerEmail: '',
    });

    this.otpForm.patchValue({
      otp1: '',
      otp2: '',
      otp3: '',
      otp4: '',
      otp5: '',
      otp6: '',
    });
  }

  toFormGroup(elements: any) {
    const group: any = {};
    elements.forEach((key: any) => {
      group[key] = new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(1),
        Validators.maxLength(1),
      ]);
    });
    return new FormGroup(group);
  }

  keyUpEventOld(event: any, index: any) {
    const validCharacters = /^[0-9]*$/;
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1;
    } else {
      pos = index + 1;
    }
    if (pos > -1 && pos < this.formInput.length) {
      if (
        (this.rows._results[index].nativeElement.value != '' &&
          validCharacters.test(
            this.rows._results[index].nativeElement.value
          )) ||
        (event.keyCode === 8 && event.which === 8)
      ) {
        this.rows._results[pos].nativeElement.focus();
      } else {
        this.rows._results[index].nativeElement.value = '';
      }
    }
  }

  keyUpEvent(event: KeyboardEvent, index: number): void {
    const validCharacters = /^[0-9]*$/;
    const currentControl = this.otpForm.get(this.formInput[index]);

    if (event.key === 'Backspace') {
      if (index > 0) {
        this.rows.toArray()[index - 1].nativeElement.focus();
        this.otpForm.get(this.formInput[index - 1])?.setValue(''); // Clear previous input
      }
      currentControl?.setValue(''); // Clear the current control
    } else if (validCharacters.test(event.key)) {
      currentControl?.setValue(event.key); // Set the value of the current control
      if (index < this.formInput.length - 1) {
        this.rows.toArray()[index + 1].nativeElement.focus(); // Move focus to the next input
      }
    }
  }
  
  encodedEmail: string = '';
  emailSubmit() {
    this.customerEnteredEmail = this.emailVerificationForm.value.customerEmail;
    console.log('this.customerEnteredEmail: ', this.customerEnteredEmail);

    if (this.emailVerificationForm.value.customerEmail != '') {
      this.loaderService.show();
      this.authService
        .sendEmailOtp({ email: this.emailVerificationForm.value.customerEmail })
        .subscribe({
          next: (res: any) => {
            if (res.success && res.data && res.data.encodedEmail != '') {
              this.encodedEmail = res.data.encodedEmail;
              this.isSendOtp = true;
              setTimeout(() => {
                this.rows._results[0].nativeElement.focus();
                this.startTimer();
              }, 100);
            } else {
              this.toastr.error(res.message, 'Error!');
            }
            this.loaderService.hide();
          },
          error: (err: any) => {
            this.loaderService.hide();
          },
        });
    } else {
      this.toastr.error('Email is required.', 'Error!');
    }
  }

  otpSubmit(): void {
    if (
      this.otpForm.value.otp1 != '' &&
      this.otpForm.value.otp2 != '' &&
      this.otpForm.value.otp3 != '' &&
      this.otpForm.value.otp4 != '' &&
      this.otpForm.value.otp5 != '' &&
      this.otpForm.value.otp6 != '' &&
      this.encodedEmail != ''
    ) {
      this.loaderService.show();
      let otp =
        '' +
        this.otpForm.value.otp1 +
        this.otpForm.value.otp2 +
        this.otpForm.value.otp3 +
        this.otpForm.value.otp4 +
        this.otpForm.value.otp5 +
        this.otpForm.value.otp6;
      this.authService
        .verifyEmailOtp({
          email: this.emailVerificationForm.value.customerEmail,
          otp: otp,
          encodedEmail: this.encodedEmail,
        })
        .subscribe({
          next: (res: any) => {
            console.log('::::res', res);

            if (res.success == true) {
              this.isSendOtp = false;
              this.dialogRef.close({
                status: true,
                email: this.emailVerificationForm.value.customerEmail,
              });
            } else {
              this.toastr.error(res.message, 'Error!');
            }
            this.loaderService.hide();
          },
          error: (err: any) => {
            this.loaderService.hide();
          },
        });
    } else {
      this.toastr.error('Otp is required.', 'Error!');
    }
  }
}
