export interface PanVerification {
  pan_number: string;
  loanId: string;
}

export const StepRoutes = [
  '/loan/employment/company-details',
  '/loan/employment/type',
  '/loan/employment/work-email',
  '/loan/employment/salary-slip',
  '/loan/net-banking',
  '/loan/upload-contacts',
  '/loan/user-approval',
  '/loan/upload-document',
  '/loan/video',
  '/loan/video-capture',
  '/loan/video-review',
  '/loan/esign',
  '/loan/emandate',
  '/loan/congratulations',
];
