<div class="footer-sec">
  <div
    class="menu-item"
    *ngFor="let item of menuDetails; let i = index"
    (click)="selectMenu(item)"
    [ngClass]="{ 'active-border': item?.isSelected }"
  >
    <div class="menu-content">
      <img [src]="item?.isSelected ? item.activeIcon : item.inactiveIcon" />
      <span [ngClass]="item?.isSelected ? 'active-text' : 'inactive-text'">{{
        item.name
      }}</span>
    </div>
  </div>
</div>
