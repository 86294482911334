<div class="app-layout-main">
  <div class="credit-app-header">
    <app-header
      [title]="common.customerNickName"
      [isShowGreeting]="isNavigationBarShow"
      [isShowTitle]="isNavigationBarShow"
      [isShowLogoutButton]="isNavigationBarShow"
    ></app-header>
  </div>
  <div
    class="content-main isLoanStepMain"
    [ngClass]="{ 'navigation-bar-none': !isNavigationBarShow }"
  >
    <router-outlet></router-outlet>
  </div>
  <div class="navigation-bar" *ngIf="isNavigationBarShow">
    <app-navigation-bar [selectedMenuIndex]="0"></app-navigation-bar>
  </div>
</div>
