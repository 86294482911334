import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StoreService } from 'src/app/services/store.service';
import { FlyyWebSDKService } from 'src/app/services/flyy-web-sdk.service';

@Component({
  selector: 'app-flyy-web',
  templateUrl: './flyy-web.component.html',
  styleUrls: ['./flyy-web.component.scss']
})
export class FlyyWebComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private store: StoreService,
    private flyySDKService: FlyyWebSDKService
  ) { }

  ngOnInit(): void {
  }

  loadBalance(): void {
    this.flyySDKService.OpenFlyySDK();
  }
}
